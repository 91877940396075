<template>
  <v-snackbar
    class="store-snackbar"
    style="z-index: 600"
    v-if="closeable || icon"
    :color="color"
    :timeout="-1"
    bottom
    v-model="snackbarActive"
  >
    <span v-html="snackbarText"></span>
    <slot name="custom-icon"></slot>
    <template v-if="closeable" v-slot:action="{ attrs }">
      <v-btn v-bind="attrs" icon small @click="closeSnackbar">
        <v-icon aria-label="Close">{{ icons.close }}</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
  <v-snackbar
    class="store-snackbar"
    style="z-index: 600"
    v-else
    :color="color"
    :timeout="-1"
    bottom
    v-model="snackbarActive"
  >
    <span v-html="snackbarText"></span>
  </v-snackbar>
</template>

<script>
// --- StoreSnackbar.vue Documentation ---
// Props:
//    color     (str):  The background color of the snackbar (the text is always white)
//    closeable (bool): Whether or not the user can close the snackbar
//    icon      (bool): Whether or not to display the close button or the element of the custom-icon slot
//    module    (str):  The name of the store module the snackbar uses ('projectTracker')
//    type      (str):  The name of the snackbar in the store ('bad')
// Notes:
//    In the module referenced in the "module" prop, make sure there are the following
//    objects in the referenced store. In this example, we'll use the 'projectTracker'
//    module.
//    In @/store/modules/projectTracker/index.js:
//      snackbars: {
//        bad: {
//          active: false,
//          text: "",
//        },
//    In @/store/modules/projectTracker/setters.js (or mutations.js)
//      USER/SNACKBAR_SET(state, payload) {
//        state.snackbars[payload.type].active = payload.active
//        state.snackbars[payload.type].text = payload.text
//      }
//    The way you would instantiate a bad snackbar (for errors, warnings) on Project Tracker would be:
//      <StoreSnackbar color="#BF0D3E" :closeable="true" :icon="true" module="projectTracker" type="bad" />
//    This would be instantiated on a high-level component, such as Home.
//
//    See @/components/project_tracker/Home.vue for an example of both a regular StoreSnackbar example
//    and a slotted StoreSnackbar example (@/components/project_tracker/CopySnackbar.vue)
// ---------------------------------------
import { mdiClose } from "@mdi/js";

export default {
  data: () => ({
    icons: {
      close: mdiClose,
    },
  }),
  props: ["color", "closeable", "icon", "type"],
  computed: {
    snackbarActive() {
      return this.$store.state.USER.snackbars[this.type].active;
    },
    snackbarText() {
      return this.$store.state.USER.snackbars[this.type].text;
    },
  },
  methods: {
    closeSnackbar() {
      this.$store.commit(`USER/SNACKBAR_SET`, {
        active: false,
        text: "",
        type: this.type,
      });
    },
  },
};
</script>

<style scoped>
.store-snackbar >>> span {
  color: var(--default-text);
}
</style>
