<template>
  <v-fade-transition>
    <v-container v-if="visible" fill-height fluid class="overlay">
      <v-row>
        <v-col cols="12" class="d-flex justify-center">
          <v-img
            :src="require('@/assets/overlays/CoolmathGames-800x600.png')"
            max-width="100%"
            max-height="600"
            contain
            style="max-width: 800px; width: 100%"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-fade-transition>
</template>

<script>
export default {
  props: ["visible"],
};
</script>

<style>
.overlay {
  background-color: #242424;
  position: fixed;
  z-index: 999999999999;
}
</style>