<template>
  <v-btn
    v-if="isCoolMath"
    block
    :color="$colors.blue"
    class="white--text mb-2"
    @click="playPastPuzzles"
  >
    Play More Puzzles
  </v-btn>
  <v-btn
    v-else
    block
    :color="$colors.blue"
    class="white--text mb-2"
    @click="playPastPuzzles"
  >
    Play Past Puzzles
  </v-btn>
</template>

<script>
export default {
  computed: {
    isCoolMath() {
      return process.env.VUE_APP_DEPLOYMENT_PLATFORM == "coolmath";
    },
  },
  methods: {
    playPastPuzzles() {
      if (this.isCoolMath) {
        this.$store.commit("USER/PLAY_PAST_PUZZLES_SET", true);
      } else {
        this.$store.commit("USER/PLAY_PAST_PUZZLES_CALENDAR_SET", true);
      }
    },
  },
};
</script>

<style>
</style>