<!-- eslint-disable vue/no-v-text-v-html-on-component -->
<template>
  <v-dialog
    v-model="active"
    width="500"
    :dark="dark"
    :persistent="!gameStarted"
    @click:outside="close"
  >
    <v-card style="border-radius: 10px">
      <v-card-title class="pt-2 pb-1">
        <v-col cols="9" class="d-flex align-center px-0">How to Play</v-col>
        <v-col cols="3" class="d-flex justify-end align-center px-0">
          <!-- <SignInMenu v-if="!user && !example" activator="button" /> -->
          <v-btn
            v-if="user && !example"
            text
            @click="$store.commit('USER/ACCOUNT_DIALOG_SET', true)"
          >
            <v-icon>
              {{ icons.account }}
            </v-icon>
          </v-btn>
        </v-col>
      </v-card-title>
      <v-card-text class="pt-0 pb-1">
        <v-carousel
          v-model="carousel"
          height="420"
          hide-delimiters
          style="border-radius: 10px"
        >
          <!-- <v-carousel-item class="vci" v-else-if="!$ark.active">
            <v-sheet
              :color="$colors.blue"
              tile
              height="420"
              class="d-flex align-center"
            >
              <v-row>
                <v-col cols="10" offset="1" class="d-flex justify-center">
                  <h1
                    style="line-height: 1"
                    :style="{
                      'font-size': $vuetify.breakpoint.xsOnly ? '30px' : '48px',
                    }"
                    class="text-center black--text"
                  >
                    Did you know...
                  </h1>
                </v-col>
                <v-col cols="8" offset="2" class="d-flex justify-center pb-0">
                  <ul class="black--text">
                    <li>
                      You can customize the interface in
                      <a
                        style="
                          color: black !important;
                          font-weight: bold;
                          text-decoration: underline;
                        "
                        @click="$store.commit('USER/SETTINGS_DIALOG_SET', true)"
                      >
                        Settings
                      </a>
                    </li>
                    <li>
                      Puzzle
                      <strong>difficulty increases</strong>
                      from Monday through Saturday.
                    </li>
                    <li v-if="!$ark.active">
                      Signed-in users can
                      <strong>play the previous week</strong>
                      of puzzles.
                    </li>
                  </ul>
                </v-col>
                <template v-if="user">
                  <v-col cols="12" class="d-flex justify-center">
                    <p class="text-center black--text">
                      Signed in as:
                      <strong>{{ user.email }}</strong>
                    </p>
                  </v-col>
                </template>
                <v-spacer />
              </v-row>
            </v-sheet>
          </v-carousel-item> -->
          <v-carousel-item v-for="(slide, i) in slides" :key="i" class="vci">
            <v-sheet
              :color="slide.color"
              tile
              height="420"
              style="margin-top: 12px"
            >
              <v-row align="center" justify="center">
                <v-spacer />
                <v-col
                  :style="{
                    height: '150px',
                    color: colorless ? 'white' : 'black',
                  }"
                  cols="10"
                  class="py-0 text-center justify-center align-center d-flex"
                  v-html="slide.text"
                />
                <v-spacer />
              </v-row>
              <v-row v-if="'image' in slide">
                <v-spacer />
                <v-col cols="12" class="d-flex justify-center py-0">
                  <v-img
                    :src="slide.image"
                    style="min-width: 250px; min-height: 250px"
                    max-height="250"
                    max-width="250"
                  />
                </v-col>
                <v-spacer />
              </v-row>
            </v-sheet>
          </v-carousel-item>
          <v-carousel-item class="vci">
            <v-sheet
              :color="$colors.red"
              tile
              height="420"
              style="margin-top: 12px"
            >
              <v-row class="pt-6">
                <v-spacer />
                <v-col
                  v-if="!$ark.active && !isCoolMath"
                  cols="12"
                  class="d-flex justify-center pt-0"
                >
                  <a href="/help-video" target="_blank">
                    <v-img
                      :src="
                        require('@/assets/instructions/blockables-instructions-5.png')
                      "
                      style="min-width: 180px; min-height: 180px"
                      max-width="180"
                      max-height="180"
                    />
                  </a>
                </v-col>
                <v-col cols="12" class="d-flex justify-center pt-0">
                  <v-img
                    @click="navToHowToPlay"
                    :src="
                      require('@/assets/instructions/blockables-instructions-6.png')
                    "
                    style="min-width: 180px; min-height: 180px; cursor: pointer"
                    max-width="180"
                    max-height="180"
                  />
                </v-col>
                <v-spacer />
              </v-row>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>
        <p style="margin: 0">{{ carousel + 1 }}/{{ presentSlides() }}</p>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-col cols="12">
          <v-btn class="close-button" :dark="dark" text block @click="close">
            Close
          </v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiCog, mdiAccount } from "@mdi/js";

export default {
  data: () => ({
    carousel: 0,
    icons: {
      account: mdiAccount,
      cog: mdiCog,
    },
    colorlessSlides: [
      {
        color: "#545454",
        image: require("@/assets/instructions/colorless/blockables-instructions-2.png"),
        text: "Find the 25-letter solution! It spirals out from the center block (use the clockwise and counterclockwise options to preview the phrase)!",
      },
      {
        color: "#545454",
        image: require("@/assets/instructions/colorless/blockables-instructions-0.png"),
        text: "Clues and their answers have the same number. The first letter goes in the square with the asterisk.",
      },
      {
        color: "#545454",
        image: require("@/assets/instructions/colorless/blockables-instructions-1.png"),
        text: "Letters of an answer must touch the previous letter.",
      },
      {
        color: "#545454",
        image: require("@/assets/instructions/colorless/blockables-instructions-3.png"),
        text: "<ul style='text-align: start;'><li>A hint for the 25-letter phrase is at the top of the screen.</li><li>Jot ideas on the Scratchpads.</li><li>The 'Spiral' buttons preview directions the 25-letter phrase could wind.</li></ul>",
      },
    ],
  }),
  computed: {
    active() {
      return this.$store.state.USER.dialogs.instructions;
    },
    colorfulSlides() {
      return [
        {
          color: "#EBE9E9",
          image: require(`@/assets/instructions/colorful/${this.folderLocation}/blockables-instructions-2.png`),
          text: 'Find the 25-letter solution! It spirals out from the center block. Use the "Clockwise" and "Counter" buttons to figure out which way the phrase is spiraled.',
        },
        {
          color: this.$colors.green,
          image: require(`@/assets/instructions/colorful/${this.folderLocation}/blockables-instructions-0.png`),
          text: "Clues and their answers are the same color. The first letter goes in the numbered square.",
        },
        {
          color: this.$colors.yellow,
          image: require(`@/assets/instructions/colorful/${this.folderLocation}/blockables-instructions-1.png`),
          text: "Letters of an answer must touch the previous letter.",
        },
        {
          color: this.$colors.orange,
          image: require(`@/assets/instructions/colorful/${this.folderLocation}/blockables-instructions-3.png`),
          text: "<ul style='text-align: start;'><li>A hint for the 25-letter phrase is at the top of the screen.</li><li>Jot ideas on the Scratchpads.</li><li>The 'Spiral' buttons preview directions the 25-letter phrase could wind.</li></ul>",
        },
      ];
    },
    colorless() {
      return this.$store.state.USER.settings.colorless;
    },
    dark() {
      return this.$store.state.USER.settings.dark;
    },
    example() {
      return this.$route.name == "How to Play";
    },
    folderLocation() {
      return this.$ark.active ? "arkadium" : "app";
    },
    gameStarted() {
      return this.$store.state.USER.gameStarted;
    },
    isCoolMath() {
      return process.env.VUE_APP_DEPLOYMENT_PLATFORM == "coolmath";
    },
    numMissedPuzzles() {
      return this.$store.state.USER.missedPuzzles.filter(
        mp => mp.symbol === null
      ).length;
    },
    slides() {
      return this.colorless ? this.colorlessSlides : this.colorfulSlides;
    },
    user() {
      return !this.$ark.active && this.$store.state.sb_user;
    },
  },
  methods: {
    close() {
      this.carousel = 0;
      if (this.example) {
        this.$store.commit("USER/GAME_START", true);
      }
      this.$store.commit("USER/INSTRUCTIONS_DIALOG_SET", false);
    },
    navToHowToPlay() {
      this.close();
      this.$router.push("/how-to-play");
    },
    async play(method) {
      if (
        this.$ark.active &&
        process.env.VUE_APP_DEPLOYMENT_PLATFORM == "arkadium-sdk"
      ) {
        await this.$ark.api.callAsyncSDKFunction("onGameStart");
      }
      if (
        this.$ark.active &&
        process.env.VUE_APP_DEPLOYMENT_PLATFORM == "arkadium"
      ) {
        this.$ark.api.handleGameStart();
      }
      this.$store.commit("USER/TIMED_SET", method == "timed");
      await this.$store.dispatch("USER/TIMER_START");
      this.$store.commit("USER/GAME_START", true);
      this.$store.commit("USER/INSTRUCTIONS_DIALOG_SET", false);
    },
    presentSlides() {
      let counter = 0;
      return counter + 5;
    },
  },
};
</script>

<style>
.v-carousel__controls {
  background: 0 !important;
}
</style>
