export default {
  COLOR_COUNTS: state => override => {
    let counts = {};
    let loopable = override ? override : state.gridColors;
    for (let i = 0; i < loopable.length; i++) {
      let row = loopable[i];
      for (let j = 0; j < row.length; j++) {
        let color = row[j];
        if (color in counts) {
          counts[color]++;
        } else {
          counts[color] = 1;
        }
      }
    }
    return counts;
  },
  GET_SPEED_EMOJI: state => {
    if (
      state.assists.checks == 0 &&
      state.assists.coordsRevealed.length == 0 &&
      state.timer < 120
    ) {
      return "👑";
    } else if (
      state.timer < 200 &&
      state.assists.reveals < 25 &&
      state.assists.coordsRevealed.length < 25
    ) {
      return "🎷";
    }
    return "🎉";
  },
  GET_TIME: state => time => {
    let t = time ? time : state.timer;
    if (t <= 0) return "0:00";
    const minutes = Math.floor(t / 60);
    const seconds = t % 60;
    const secondsString = seconds > 9 ? seconds : "0" + seconds;
    return minutes + ":" + secondsString;
  },
  HELPER_EMOJIS: state => {
    return `${state.assists.checks} ✅ & ${state.assists.coordsRevealed.length} 👁️`;
  },
  IS_MOBILE: () => {
    let ua = navigator.userAgent.toLowerCase();
    let isMobile =
      /android|webos|iphone|ipod|blackberry|iemobile|opera mini/i.test(ua);
    let isTablet =
      /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|playbook|(puffin(?!.*(ip|ap|wp))))/.test(
        ua
      );
    return isMobile && !isTablet;
  },
  UNSUPPORTED_BROWSER: () => {
    let ua = navigator.userAgent.toLowerCase();
    return /kindle|silk/i.test(ua);
  },
  TIME_REMAINING: state => {
    let todayEst = new Date(
      new Date().toLocaleString("en-US", {
        timeZone: "America/New_York",
      })
    );

    let h = 23 - todayEst.getHours();
    let m = 59 - todayEst.getMinutes();
    let s = 59 - todayEst.getSeconds() + state.countdown - state.countdown;

    h = h < 10 ? "0" + h : h;
    m = m < 10 ? "0" + m : m;
    s = s < 10 ? "0" + s : s;

    return h + ":" + m + ":" + s;
  },
  TODAY: () => platform => {
    let d = new Date(
      new Date().toLocaleString("en-US", {
        timeZone: "America/New_York",
      })
    );

    let yyyy = d.getFullYear();
    let mm = d.getMonth() + 1 > 12 ? 1 : d.getMonth() + 1;
    mm = mm > 9 ? mm : "0" + mm;
    let dd = d.getDate() > 9 ? d.getDate() : "0" + d.getDate();
    if (platform == "coolmath") {
      let puzzle300 = new Date(
        new Date("2024-03-20").toLocaleString("en-US", {
          timeZone: "America/New_York",
        })
      );
      if (d > puzzle300) {
        return "2024-03-20";
      }
    }
    return `${yyyy}-${mm}-${dd}`;
  },
};
