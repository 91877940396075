<template>
  <v-app>
    <Account />
    <!-- <ClosingUpShop /> -->
    <Instructions />
    <PlayPastPuzzles
      v-if="isCoolMath"
      :ppp-active="$store.state.USER.playPastPuzzles"
      @modalClose="$store.commit('USER/PLAY_PAST_PUZZLES_SET', false)"
    />
    <PastPuzzleCalendar
      v-else
      :ppp-active="renderPastPuzzleCalendar"
      @modalClose="$store.commit('USER/PLAY_PAST_PUZZLES_CALENDAR_SET', false)"
    />
    <Settings />
    <CoolMathOverlay v-if="isCoolMath" :visible="showCoolMath" />
    <template v-if="unsupportedBrowser">
      <v-container style="background: #1e1e1e; height: 100%">
        <v-row>
          <v-col cols="8" offset="2">
            <v-card :dark="dark" flat style="overflow: hidden">
              <v-row>
                <v-spacer />
                <v-col cols="2" class="pb-0 pt-4">
                  <v-img
                    v-if="!$ark.active"
                    :aspect-ratio="1 / 1"
                    height="100"
                    alt="Blockables logo"
                    contain
                    :src="require('@/assets/blockables-square.png')"
                    transition="scale-transition"
                  />
                  <v-img
                    v-else
                    :aspect-ratio="1 / 1"
                    height="100"
                    alt="Blockables logo"
                    contain
                    :src="require('@/assets/blockables-arkadium.png')"
                    transition="scale-transition"
                  />
                </v-col>
                <v-spacer />
              </v-row>
              <v-row style="height: 150px">
                <v-spacer />
                <v-col
                  cols="8"
                  class="pt-0 justify-center align-center d-flex text-center"
                >
                  <p style="font-size: 24px">
                    Blockables is not supported on this browser.
                  </p>
                </v-col>
                <v-spacer />
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <!-- Home Screen -->
    <HomeScreen
      v-else-if="!gameStarted && ['Home', 'Puzzle'].includes($route.name)"
    ></HomeScreen>
    <template v-else-if="$vuetify.breakpoint.xsOnly">
      <v-app-bar
        class="header portrait"
        style="z-index: 1000"
        app
        dense
        :dark="dark"
      >
        <v-container class="portrait">
          <v-row>
            <v-spacer />
            <v-col
              cols="2"
              md="3"
              lg="3"
              xl="2"
              class="d-flex align-center cursor-pointer"
            >
              <v-img
                v-if="$vuetify.breakpoint.smAndUp && $ark.active"
                alt="Blockables logo"
                class="shrink mr-2"
                contain
                :src="require('@/assets/blockables-arkadium.png')"
                transition="scale-transition"
                width="32px"
                @click="$router.push('/')"
              />
              <v-img
                v-if="$vuetify.breakpoint.smAndUp && !$ark.active"
                alt="Blockables logo"
                class="shrink mr-2"
                contain
                :src="require('@/assets/blockables-square.png')"
                transition="scale-transition"
                width="32px"
                @click="$router.push('/')"
              />
              <span
                v-else
                :style="{ opacity: timed ? 1 : 0.5, 'font-size': '14px' }"
              >
                {{ example || !timed ? "0:00" : timer }}
              </span>
              <h1
                v-if="$vuetify.breakpoint.mdAndUp"
                class="cursor-pointer"
                style="font-size: 32px"
                :style="{
                  color: dark ? 'white' : 'black !important',
                  'font-size': '32px',
                }"
                tabindex="-1"
                @click="$router.push('/')"
              >
                Blockables
              </h1>
            </v-col>
            <v-col
              cols="8"
              sm="8"
              md="6"
              lg="4"
              xl="4"
              class="d-flex align-center justify-center"
            >
              <div class="metadata-wrapper align-center">
                <span>
                  <span
                    id="ordinal-and-category"
                    :style="{
                      'font-size': '16px',
                      'font-weight': '700',
                    }"
                    :tutorial="
                      example
                        ? `example-cell-${exampleStepper}-category`
                        : false
                    "
                  >
                    {{ !example ? `#${metadata.puzzleNumber}` : "Hint" }}:
                    {{ metadata.category }}
                  </span>
                </span>
              </div>
            </v-col>
            <v-col
              cols="2"
              md="3"
              lg="3"
              xl="2"
              class="d-flex align-center justify-end"
            >
              <v-btn
                v-if="checkWon && !playing && gameStarted && !example"
                text
                class="navbar-button px-1"
                style="min-width: 0"
                tabindex="-1"
                @click="win = true"
              >
                <span
                  v-if="symbol() != '🎉'"
                  class="fast-swing"
                  style="font-size: 24px"
                >
                  {{ symbol() }}
                </span>
                <span v-else class="swing" style="font-size: 24px">
                  {{ symbol() }}
                </span>
              </v-btn>
              <v-btn
                v-if="checkWon && !playing && gameStarted && example"
                text
                class="navbar-button px-1"
                style="min-width: 0"
                tabindex="-1"
                @click="exampleWin = true"
              >
                <span class="swing" style="font-size: 24px">🎉</span>
              </v-btn>
              <span
                v-if="$vuetify.breakpoint.smAndUp"
                :style="{ opacity: timed ? 1 : 0.5 }"
                class="pr-3"
              >
                {{ example || !timed ? "0:00" : timer }}
              </span>
              <v-icon
                aria-label="Show menu"
                tabindex="-1"
                @click="showNavDrawer += 1"
              >
                {{ icons.menu }}
              </v-icon>
            </v-col>
            <v-spacer />
          </v-row>
        </v-container>
      </v-app-bar>

      <BlockablesNavbar class="portrait" :show="showNavDrawer" />

      <v-main
        class="portrait"
        :style="{
          backgroundColor: dark ? 'rgb(40, 40, 40)' : 'inherit',
        }"
      >
        <router-view />
        <StoreSnackbar :color="$colors.red" :closeable="true" type="bad" />
        <StoreSnackbar
          :color="$colors.green"
          :closeable="false"
          type="success"
        />
      </v-main>

      <v-footer
        v-if="$route.meta.showKeyboard"
        class="sticky-keyboard py-0 portrait"
        :class="example ? 'keyboard-punch-through' : ''"
        :style="{
          backgroundColor: dark ? 'rgb(40, 40, 40)' : '#ececec',
          height: '152px',
        }"
        :dark="dark"
      >
        <SimpleKeyboard
          class="sticky-keyboard"
          :class="example ? 'keyboard-punch-through' : ''"
          :style="{
            backgroundColor: dark ? 'rgb(40, 40, 40)' : '#ececec',
            height: '152px',
          }"
        />
      </v-footer>
    </template>
    <template v-else>
      <v-app-bar class="header" style="z-index: 1000" app dense :dark="dark">
        <v-container>
          <v-row>
            <v-spacer />
            <v-col
              cols="2"
              md="3"
              lg="3"
              xl="2"
              class="d-flex align-center cursor-pointer"
            >
              <v-img
                v-if="$vuetify.breakpoint.smAndUp && $ark.active"
                alt="Blockables logo"
                class="shrink mr-2"
                contain
                :src="require('@/assets/blockables-arkadium.png')"
                transition="scale-transition"
                width="32px"
                @click="navToHomeAndPullPuzzle()"
              />
              <v-img
                v-if="$vuetify.breakpoint.smAndUp && !$ark.active"
                alt="Blockables logo"
                class="shrink mr-2"
                contain
                :src="require('@/assets/blockables-square.png')"
                transition="scale-transition"
                width="32px"
                @click="navToHomeAndPullPuzzle()"
              />
              <h1
                v-if="$vuetify.breakpoint.mdAndUp"
                class="cursor-pointer"
                style="font-size: 32px"
                :style="{
                  color: dark ? 'white' : 'black !important',
                  'font-size': '32px',
                }"
                tabindex="-1"
                @click="navToHomeAndPullPuzzle()"
              >
                Blockables
              </h1>
            </v-col>
            <v-col
              cols="8"
              sm="8"
              md="6"
              lg="4"
              xl="4"
              class="d-flex align-center justify-center"
            >
              <div class="metadata-wrapper align-center">
                <span>
                  <span
                    id="ordinal-and-category"
                    :style="{
                      'font-size': '16px',
                      'font-weight': '700',
                    }"
                    :tutorial="
                      example
                        ? `example-cell-${exampleStepper}-category`
                        : false
                    "
                  >
                    {{ !example ? `#${metadata.puzzleNumber}` : "Hint" }}:
                    {{ metadata.category }}
                  </span>
                </span>
              </div>
            </v-col>
            <v-col
              cols="2"
              md="3"
              lg="3"
              xl="2"
              class="d-flex align-center justify-end"
            >
              <v-btn
                v-if="checkWon && !playing && gameStarted && !example"
                text
                class="navbar-button px-1"
                style="min-width: 0"
                tabindex="-1"
                @click="win = true"
              >
                <span
                  v-if="symbol() != '🎉'"
                  class="fast-swing"
                  style="font-size: 24px"
                >
                  {{ symbol() }}
                </span>
                <span v-else class="swing" style="font-size: 24px">
                  {{ symbol() }}
                </span>
              </v-btn>
              <v-btn
                v-if="checkWon && !playing && gameStarted && example"
                text
                class="navbar-button px-1"
                style="min-width: 0"
                tabindex="-1"
                @click="exampleWin = true"
              >
                <span class="swing" style="font-size: 24px">🎉</span>
              </v-btn>
              <span
                v-if="$vuetify.breakpoint.smAndUp"
                :style="{ opacity: timed ? 1 : 0.5 }"
                class="pr-3"
              >
                {{ example || !timed ? "0:00" : timer }}
              </span>
              <v-icon
                aria-label="Show menu"
                tabindex="-1"
                @click="showNavDrawer += 1"
              >
                {{ icons.menu }}
              </v-icon>
            </v-col>
            <v-spacer />
          </v-row>
        </v-container>
      </v-app-bar>

      <BlockablesNavbar :show="showNavDrawer" />

      <v-main
        :style="{
          backgroundColor: dark ? 'rgb(40, 40, 40)' : 'inherit',
        }"
      >
        <router-view />
        <StoreSnackbar :color="$colors.red" :closeable="true" type="bad" />
        <StoreSnackbar
          :color="$colors.green"
          :closeable="false"
          type="success"
        />
      </v-main>
      <template v-if="$route.meta.showKeyboard">
        <v-footer
          v-if="!hk"
          class="sticky-keyboard py-0"
          :class="example ? 'keyboard-punch-through' : ''"
          :style="{
            backgroundColor: dark ? 'rgb(40, 40, 40)' : '#ececec',
            height: '152px',
          }"
          :dark="dark"
        >
          <v-container>
            <v-row>
              <v-spacer />
              <v-col class="pb-0" cols="12" lg="10" xl="8">
                <SimpleKeyboard
                  class="sticky-keyboard"
                  :class="example ? 'keyboard-punch-through' : ''"
                  :style="{
                    backgroundColor: dark ? 'rgb(40, 40, 40)' : '#ececec',
                    height: '152px',
                  }"
                />
              </v-col>
              <v-spacer />
            </v-row>
          </v-container>
        </v-footer>
        <v-footer
          v-else
          class="sticky-keyboard py-0"
          :style="{
            backgroundColor: dark ? 'rgb(40, 40, 40)' : '#ececec',
            height: '24px',
          }"
          :dark="dark"
        >
          <v-container>
            <v-row>
              <v-spacer />
              <v-col
                class="d-flex justify-center align-center pa-0"
                cols="12"
                lg="10"
                xl="8"
              >
                <p class="show-keyboard-text" @click="showKeyboard">
                  No physical keyboard? Click here for in-game keyboard. You can
                  always hide it again in "Settings".
                </p>
              </v-col>
              <v-spacer />
            </v-row>
          </v-container>
        </v-footer>
      </template>
    </template>
  </v-app>
</template>

<script>
import {
  mdiChevronDown,
  mdiHelpCircleOutline,
  mdiCommentQuote,
  mdiMenu,
} from "@mdi/js";

import Account from "@/components/dialogs/Account";
import BlockablesNavbar from "@/components/BlockablesNavbar";
// import ClosingUpShop from "@/components/dialogs/ClosingUpShop.vue";
import CoolMathOverlay from "@/components/overlays/CoolMathOverlay.vue";
import HomeScreen from "@/components/HomeScreen";
import Instructions from "@/components/dialogs/Instructions.vue";
import PlayPastPuzzles from "./components/dialogs/PlayPastPuzzles.vue";
import PastPuzzleCalendar from "./components/dialogs/PastPuzzleCalendar.vue";
import Settings from "@/components/dialogs/Settings.vue";
import StoreSnackbar from "@/components/StoreSnackbar";
import SimpleKeyboard from "@/components/SimpleKeyboard";

export default {
  name: "App",
  components: {
    Account,
    BlockablesNavbar,
    // ClosingUpShop,
    CoolMathOverlay,
    HomeScreen,
    Instructions,
    PlayPastPuzzles,
    PastPuzzleCalendar,
    Settings,
    SimpleKeyboard,
    StoreSnackbar,
  },
  data: () => ({
    icons: {
      chevronDown: mdiChevronDown,
      commentQuote: mdiCommentQuote,
      helpCircleOutline: mdiHelpCircleOutline,
      menu: mdiMenu,
    },
    showCoolMath: true,
    showNavDrawer: 0,
  }),
  computed: {
    dark() {
      return this.$store.state.USER.settings.dark;
    },
    date() {
      if (this.$route.name == "Home") return this.today;
      else return this.$route.params.d;
    },
    defaultSettings() {
      return {
        colorless: false,
        dark: true,
        hk: true,
        silent: false,
        unwindSpiral: true,
      };
    },
    dimension() {
      return this.$store.state.PUZZLE.dimension;
    },
    emojiMap() {
      return {
        crown: "👑",
        sax: "🎷",
        tada: "🎉",
      };
    },
    example() {
      return this.$route.name == "How to Play";
    },
    exampleStepper() {
      return this.$store.state.USER.exampleStepper;
    },
    exampleWin: {
      get() {
        return this.$store.state.USER.dialogs.exampleWin;
      },
      set(value) {
        this.$store.state.USER.dialogs.exampleWin = value;
      },
    },
    gameStarted() {
      return this.$store.state.USER.gameStarted;
    },
    hk() {
      return this.$store.state.USER.settings.hk;
    },
    home() {
      return this.isCoolMath ? "/puzzles/2023-05-26" : "/";
    },
    instructions: {
      get() {
        return this.$store.state.USER.dialogs.instructions;
      },
      set(value) {
        this.$store.state.USER.dialogs.instructions = value;
      },
    },
    isCoolMath() {
      return process.env.VUE_APP_DEPLOYMENT_PLATFORM == "coolmath";
    },
    isMobile() {
      return this.$store.getters["USER/IS_MOBILE"];
    },
    isProd() {
      return process.env.VUE_APP_ENVIRONMENT === "prod";
    },
    metadata() {
      return this.$store.state.PUZZLE.metadata;
    },
    offset() {
      if (this.$route.name == "Home") return 0;
      let d = this.$route.params.d;
      let o = null;
      try {
        let then = d.split("-");
        then = new Date(then[0], then[1] - 1, then[2]);
        let now = this.today.split("-");
        now = new Date(now[0], now[1] - 1, now[2]);
        o = Math.round((then - now) / (1000 * 60 * 60 * 24));
      } catch {
        o = null;
      }
      return o;
    },
    playing() {
      return this.$store.state.USER.playing;
    },
    puzzleAccess() {
      return true;
    },
    renderPastPuzzleCalendar() {
      return this.$store.state.USER.playPastPuzzlesCalendar;
    },
    revealPuzzle() {
      return this.$store.state.USER.dialogs.revealPuzzle;
    },
    settingsDialog() {
      return this.$store.state.USER.dialogs.settings;
    },
    stats() {
      return this.$store.state.USER.stats;
    },
    storeSettings() {
      return this.$store.state.USER.settings;
    },
    unsupportedBrowser() {
      return this.$store.getters["USER/UNSUPPORTED_BROWSER"];
    },
    user() {
      return this.$store.state.sb_user;
    },
    timed() {
      return !this.$store.state.USER.settings.untimed;
    },
    timer() {
      return this.$store.getters["USER/GET_TIME"](null);
    },
    today() {
      return this.$store.getters["USER/TODAY"](
        process.env.VUE_APP_DEPLOYMENT_PLATFORM
      );
    },
    win: {
      get() {
        return this.$store.state.USER.dialogs.win;
      },
      set(value) {
        this.$store.state.USER.dialogs.win = value;
      },
    },
    winByGrid() {
      return this.$store.getters["PUZZLE/WIN_BY_GRID"];
    },
  },
  watch: {
    offset: {
      async handler() {
        if (["Home", "Puzzle"].includes(this.$route.name)) {
          let date = await this.$store.dispatch(
            "LOCAL_STORAGE_GET",
            "blockables-date"
          );
          if (!this.isProd) {
            await this.fetchPuzzle();
          } else if (this.offset > 0 || !this.puzzleAccess) {
            this.$router.push(this.home);
          } else if (date) {
            await this.fetchPuzzle();
          } else {
            console.log("No puzzle fetched");
          }
        }
        this.showNavDrawer = 0;
      },
      immediate: true,
    },
    "$route.path": {
      async handler() {
        this.determineGridProgress();
        let date = await this.$store.dispatch(
          "LOCAL_STORAGE_GET",
          "blockables-date"
        );
        if (this.$ark.active && date) {
          await this.fetchPuzzle();
        }
      },
      deep: true,
    },
  },
  async mounted() {
    this.$analytics.logEvent("page_load", {
      blockables_origin: process.env.VUE_APP_DEPLOYMENT_PLATFORM,
    });
    window.exitTutorialAndGoHome = () => {
      this.$tour.finishTour();
      this.$router.push(this.home);
    };
    window.progressTutorial = () => {
      this.$tour.nextStep();
    };
    if (this.isCoolMath) {
      setTimeout(() => {
        this.showCoolMath = false;
      }, 3000);
      document.addEventListener("adBreakStart", () => {
        this.$store.state.USER.audio.volume = 0;
      });
      // To trigger the event Listener adBreakComplete
      document.addEventListener("adBreakComplete", () => {
        this.$store.state.USER.audio.volume = 0.4;
      });
    }
    await this.$store.dispatch("USER_GET");
    this.$store.commit("USER/COUNTDOWN_START");
    const ls_blockables_date = await this.$store.dispatch(
      "LOCAL_STORAGE_GET",
      "blockables-date"
    );
    if (!ls_blockables_date) {
      this.$router.push("/how-to-play");
      return;
    }
    if (this.isCoolMath) {
      this.$router.push("/puzzles/2023-05-26");
    }
    this.determineGridProgress();
    try {
      const ls_blockables_settings = await this.$store.dispatch(
        "LOCAL_STORAGE_GET",
        "blockables-settings"
      );
      if (ls_blockables_settings) {
        await this.$store.dispatch(
          "USER/SETTINGS_SET",
          JSON.parse(ls_blockables_settings)
        );
      } else {
        await this.$store.dispatch("USER/SETTINGS_SET", this.defaultSettings);
      }
    } catch (e) {
      await this.$store.dispatch("USER/SETTINGS_SET", this.defaultSettings);
    }
    await this.fetchPuzzle();
    if (!this.$ark.active) {
      if (this.user) {
        const ls_blockables_token = await this.$store.dispatch(
          "LOCAL_STORAGE_GET",
          "sb-cterwiozjbjdeoazbzdd-auth-token"
        );
        let token = JSON.parse(ls_blockables_token);
        let u = this.user;
        if (token.expires_at < Math.floor(Date.now() / 1000)) {
          const { data, error } = await this.$db.auth.refreshSession();
          if (error) {
            this.$store.commit("SNACKBAR_SET", {
              type: "bad",
              text: "ERROR: Could not refresh token. " + error,
              active: true,
            });
            return;
          }
          u = data.user;
        }
        this.$store.commit("USER_SET", u);
        await this.$store.dispatch("USER/GET_STATS_FROM_DB", u);
        return;
      }
      const {
        data: { user },
      } = await this.$db.auth.getUser();
      if (user) {
        this.$store.commit("USER_SET", user);
        await this.$store.dispatch("USER/GET_STATS_FROM_DB", user);
      }
    }
  },
  methods: {
    async determineGridProgress() {
      try {
        let gridProgress = await this.$store.dispatch(
          "LOCAL_STORAGE_GET",
          "blockables-time"
        );
        let progressDate = await this.$store.dispatch(
          "LOCAL_STORAGE_GET",
          "blockables-date"
        );
        let puzzleDate =
          this.$route.name == "Home" ? this.today : this.$route.params.d;
        if (gridProgress && progressDate == puzzleDate) {
          this.$store.commit("USER/GAME_START", true);
        } else {
          this.$store.commit("USER/GAME_START", false);
        }
      } catch {
        this.$store.commit("USER/GAME_START", false);
      }
    },
    async checkWon() {
      try {
        let won = await this.$store.dispatch(
          "LOCAL_STORAGE_GET",
          "blockables-won-today"
        );
        return won;
      } catch {
        return false;
      }
    },
    closeInstructions() {
      if (this.gameStarted) {
        this.$store.commit("USER/INSTRUCTIONS_DIALOG_SET", false);
      }
    },
    closeRevealPuzzle() {
      this.$store.commit("USER/PUZZLE_DIALOG_SET", 0);
    },
    async confirmRevealPuzzle() {
      await this.$store.dispatch("USER/PUZZLE_CONFIRM_REVEAL", 1);
    },
    closeSettingsDialog() {
      this.$store.commit("USER/SETTINGS_DIALOG_SET", false);
    },
    async navToHomeAndPullPuzzle() {
      this.$router.push(this.home);
      await this.fetchPuzzle();
    },
    async restoreLocalStorageState(lsDate, lsTimed) {
      if (lsDate == this.date) {
        await this.$store.dispatch("USER/REINITIALIZE");
      }

      // Check if player has some letter ideas
      const lsGrid = await this.$store.dispatch(
        "LOCAL_STORAGE_GET",
        "blockables-grid"
      );
      if (lsGrid && lsDate == this.date) {
        let lsGridArray = JSON.parse(lsGrid);
        for (let r = 0; r < this.dimension; r++) {
          for (let c = 0; c < this.dimension; c++) {
            this.$store.commit("PUZZLE/INSERT_LETTER_INTO_PLAYER_GRID", {
              letter: lsGridArray[r][c],
              r: r,
              c: c,
            });
            document.getElementById("r" + r + "c" + c).innerText =
              lsGridArray[r][c];
          }
        }
      }

      let lsClues = await this.$store.dispatch(
        "LOCAL_STORAGE_GET",
        "blockables-clues"
      );
      if (lsClues && lsDate == this.date) {
        lsClues = JSON.parse(lsClues);
        this.$store.commit("PUZZLE/CLUES_SET", lsClues);
      }

      // Check if player has some scratchpad ideas
      let lsScratches = await this.$store.dispatch(
        "LOCAL_STORAGE_GET",
        "blockables-scratches"
      );
      if (lsScratches && lsDate == this.date) {
        lsScratches = JSON.parse(lsScratches);
        this.$store.commit("PUZZLE/SCRATCHES_SET", lsScratches);
      }

      // Check if player has a time going
      let lsTime = await this.$store.dispatch(
        "LOCAL_STORAGE_GET",
        "blockables-time"
      );
      if (lsTime !== null && lsTimed !== null && lsDate == this.date) {
        lsTimed = lsTimed == "true";
        // this.$store.commit("USER/TIMED_SET", lsTimed);
        this.$store.commit("USER/TIMER_SET", lsTime);
        if (!this.won()) {
          await this.$store.dispatch("USER/TIMER_START");
        }
      }
    },
    symbol() {
      return this.$store.getters["USER/GET_SPEED_EMOJI"];
    },
    async showKeyboard() {
      let settings = this.storeSettings;
      settings["hk"] = false;
      await this.$store.dispatch("USER/SETTINGS_SET", settings);
    },
    async fetchPuzzle() {
      if (this.$route.name == "Create") return;
      if (this.example) return;
      try {
        await this.$store.dispatch("PUZZLE/FETCH", {
          date: this.date,
          platform: process.env.VUE_APP_DEPLOYMENT_PLATFORM,
        });
      } catch (e) {
        if (!this.isCoolMath) {
          this.$store.commit("USER/SNACKBAR_SET", {
            active: true,
            type: "bad",
            text: "001 Blockables was unable to contact the server. Please check your connection or try again later.",
          });
        }
      }
      this.$nextTick(async () => {
        try {
          let lsDate = await this.$store.dispatch(
            "LOCAL_STORAGE_GET",
            "blockables-date"
          );
          let lsTimed = await this.$store.dispatch(
            "LOCAL_STORAGE_GET",
            "blockables-timed"
          );
          await this.restoreLocalStorageState(lsDate, lsTimed);
        } catch {
          // localstorage inaccessible, ignore it
        }
      });
    },
    won() {
      return this.winByGrid;
    },
  },
};
</script>

<style>
.tour-guide {
  font-family: "Roboto", sans-serif;
  background: #333;
  color: white;
  text-align: center;
}

.tg-backdrop {
  pointer-events: none;
}

.tg-dialog-btn {
  color: white !important;
}

@media (max-width: 599px) {
  html {
    overflow: hidden;
    width: 100%;
  }

  body {
    width: 100%;
    height: 100%;
    position: fixed;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
}

* {
  touch-action: manipulation;
}

#app {
  background-color: rgb(235, 233, 233);
}

#arrow-block > svg {
  height: 100%;
  width: 100%;
}

.assist-subheader {
  font-size: 0.75rem;
}

.show-keyboard-text {
  cursor: pointer;
  font-size: 14px;
  text-decoration: underline;
  text-align: center;
  margin-bottom: 0 !important;
}

.v-navigation-drawer__append {
  position: fixed !important;
  bottom: 0 !important;
}

.v-navigation-drawer--temporary {
  z-index: 10000000;
}

.sticky-keyboard {
  position: sticky;
  position: -webkit-sticky;
  height: 152px;
  bottom: 0;
  z-index: 4;
  background-color: #ececec;
}

.keyboard-punch-through {
  z-index: 1000;
}

a {
  text-decoration: none;
  color: white !important;
}

.navbar-button::before {
  background-color: transparent !important;
}

.v-btn--block:not(.v-btn--disabled):not(.close-button) .v-btn__content,
.v-btn--rounded.v-btn--active:not(.v-btn--disabled) .v-btn__content {
  color: black !important;
}

.navbar-button {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0;
  text-indent: 0;
  text-transform: none;
  box-shadow: none;
}

.cursor-pointer {
  /* font-family: "Courier New", Courier, monospace; */
  /* font-size: 16px; */
  cursor: pointer;
}

.v-navigation-drawer__content {
  overflow-y: hidden;
}

.metadata-wrapper {
  align-self: center;
  justify-self: center;
  display: flex;
  height: 48px;
  text-align: center;
}

.swing {
  margin-top: -6px;
  animation: swing ease-in-out 1s infinite alternate;
}

@keyframes swing {
  0% {
    transform: rotate(10deg);
  }

  100% {
    transform: rotate(-10deg);
  }
}

.fast-swing {
  animation: swing ease-in-out 0.5s infinite alternate;
}

@keyframes swing {
  0% {
    transform: rotate(15deg);
  }

  100% {
    transform: rotate(-15deg);
  }
}

#accounts-tabs .v-slide-group__prev {
  display: none !important;
}

/* (A) WRONG ORIENTATION - SHOW MESSAGE HIDE CONTENT */
@media screen and (max-height: 599px) and (orientation: landscape) {
  .turn {
    display: block;
  }

  .portrait {
    display: none !important;
  }
}

/* (B) CORRECT ORIENTATION - SHOW CONTENT HIDE MESSAGE */
@media screen and (max-width: 599px) and (orientation: portrait) {
  .turn {
    display: none;
  }

  .portrait {
    display: block;
  }
}
</style>
