<template>
  <v-col
    v-if="$vuetify.breakpoint.smAndUp"
    id="clue-container"
    cols="12"
    sm="6"
    lg="5"
    xl="4"
    class="py-0"
  >
    <v-list
      v-if="clues"
      id="CL-custom-scroll"
      dense
      :style="{ backgroundColor: dark ? 'rgb(40, 40, 40)' : 'inherit' }"
      class="py-0 responsive-clue-list"
    >
      <Clue
        v-for="clue in clues"
        :key="clue.ordinal"
        :assignedFocus="assignedFocus"
        :clue="clue"
        :scratches="scratches"
        :selectedClue="clueOfSelected"
        @scratchpadFocused="focusScratchpad"
        :tutorial="example && clue.ordinal == 1 ? `example-clue-1` : false"
      />
    </v-list>
    <slot name="additional-actions"></slot>
    <v-row v-if="example">
      <v-col cols="12" class="d-flex justify-center">
        <a
          @click="showInstructions"
          class="text-center"
          :style="{
            color: dark ? 'white' : 'black !important',
            'text-decoration': 'underline',
          }"
        >
          No tutorial popping up?
          <br />
          Click here.
        </a>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import Clue from "@/components/grid/Clue.vue";

export default {
  components: {
    Clue,
  },
  computed: {
    assignedFocus: {
      get() {
        return this.$store.state.PUZZLE.assignedFocus;
      },
      set(af) {
        this.$store.commit("PUZZLE/ASSIGNED_FOCUS_SET", af);
      },
    },
    assistPiece() {
      return this.$store.state.USER.assists.piece;
    },
    colorless() {
      return this.$store.state.USER.settings.colorless;
    },
    clueOfSelected() {
      return this.$store.getters["PUZZLE/CLUE_OF_SELECTED"];
    },
    clues() {
      return this.$store.state.PUZZLE.clues;
    },
    dark() {
      return this.$store.state.USER.settings.dark;
    },
    example() {
      return this.$route.name == "How to Play";
    },
    exampleStepper() {
      return this.$store.state.USER.exampleStepper;
    },
    gridColors() {
      return this.$store.state.PUZZLE.gridColors;
    },
    mobileKeypress() {
      return this.$store.state.USER.mobileKeypress;
    },
    numberPlacements() {
      return this.$store.state.PUZZLE.numberPlacements;
    },
    scratches() {
      return this.$store.state.PUZZLE.scratches;
    },
  },
  watch: {
    scratches: {
      async handler() {
        try {
          let s = JSON.stringify(this.scratches);
          if (s != "{}") {
            await this.$store.dispatch("LOCAL_STORAGE_SET", {
              id: "blockables-scratches",
              value: JSON.stringify(this.scratches),
            });
          }
        } catch {
          // localstorage inaccessible, ignore it
        }
      },
      deep: true,
    },
  },
  methods: {
    focusScratchpad(ordinal) {
      // this.selectColor(ordinal);
      const cells = document.querySelectorAll(".bb-cell");
      for (let i = 0; i < cells.length; i++) {
        cells[i].classList.remove("selected");
      }
      this.assignedFocus = "scratchpad-" + ordinal;
    },
    // selectColor(ordinal) {
    //   let desiredColor = this.clues[ordinal - 1].color;
    //   this.gridColors.forEach((r, rIndex) => {
    //     r.forEach((c, cIndex) => {
    //       if (c == desiredColor) {
    //         this.$store.commit("PUZZLE/SELECT", { r: rIndex, c: cIndex });
    //       }
    //     });
    //   });
    // },
    showInstructions() {
      this.$store.commit("USER/INSTRUCTIONS_DIALOG_SET", true);
    },
  },
};
</script>

<style scoped>
.bb-table {
  border-collapse: collapse;
  z-index: 0;
  position: absolute;
  width: 100%;
  margin: 0;
}

.table-combiner {
  position: relative;
}

#skeleton {
  z-index: 1;
  pointer-events: none;
}

.clockwise {
  transform: scaleY(1);
  -webkit-transform: scaleY(1);
}

.counterclockwise {
  transform: scaleY(-1);
  -webkit-transform: scaleY(-1);
}

.hidden {
  display: none;
}

.arrows {
  position: absolute;
  z-index: 3;
  opacity: 0.25;
  pointer-events: none;
  border: 1px solid transparent;
}

.arrows-right {
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
}

.arrows-down {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
}

.arrows-left {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}

.arrows-up {
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
}

.selected {
  box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.75) inset;
  -webkit-box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.75) inset;
  -moz-box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.75) inset;
}

.bb-cell {
  width: 20%;
  vertical-align: middle;
  text-transform: uppercase;
  border: 2px solid black;
  color: black;
  font-size: 3.8vw;
  text-align: center;
  caret-color: transparent;
  cursor: pointer;
}

.bb-cell:focus {
  box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.75) inset;
  -webkit-box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.75) inset;
  -moz-box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.75) inset;
}

.number-box {
  position: absolute;
  padding: 0 5px;
  font-size: 20px;
}

.check-x {
  padding: 0 5px;
  font-size: 20px;
  float: right;
}

@media (max-width: 599px) {
  .number-box {
    font-size: 5vw;
  }

  .check-x {
    font-size: 5vw;
  }

  .table-combiner {
    height: 100vw;
  }

  .bb-table {
    height: 90vw;
  }

  .bb-cell {
    font-size: 10vw;
  }

  .bb-arrow {
    font-size: 8vw !important;
  }
}

@media (min-width: 1600px) {
  .bb-cell {
    font-size: 2.8vw;
  }
}

[contenteditable] {
  outline: 0px solid transparent;
}

.grey-border {
  border: 1px #333 solid;
}

.bb-number-cell {
  width: 20%;
  vertical-align: text-top;
  background-color: rgba(0, 0, 0, 0);
  /* border: 2px solid black; */
  color: black;
  font-size: 20px;
}

.bb-number-cell-absolute-positioner {
  position: absolute;
  width: 20%;
}

.bb-arrow {
  position: absolute;
  color: black !important;
  width: 50%;
  height: 50%;
}

.bb-arrow-up {
  margin-top: -35%;
  margin-left: 25%;
}

.bb-arrow-down {
  margin-top: 80%;
  margin-left: 25%;
}

.bb-arrow-left {
  margin-top: 25%;
  margin-left: -30%;
}

.bb-arrow-right {
  margin-top: 25%;
  margin-left: 80%;
}

.responsive-clue-list {
  height: min(var(--grid-height), 100%) !important;
  overflow-y: auto;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
#CL-custom-scroll {
  scrollbar-width: auto;
  scrollbar-color: #ccc rgba(0, 0, 0, 0);
  overflow-y: scroll;
}

/* Chrome, Edge, and Safari */
#CL-custom-scroll::-webkit-scrollbar {
  width: 4px;
}

#CL-custom-scroll::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}

#CL-custom-scroll::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
  border: 0;
}

*.unselectable {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;

  /*
     Introduced in IE 10.
     See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
   */
  -ms-user-select: none;
  user-select: none;
}
</style>
