<template>
  <v-row v-if="$vuetify.breakpoint.xsOnly" class="sticky-clue">
    <v-col v-if="clues" cols="12" class="py-0">
      <v-list dense class="py-0">
        <Clue
          :assignedFocus="assignedFocus"
          :clue="clueOfSelected"
          :scratches="scratches"
          :selectedClue="{ ordinal: -1 }"
          @scratchpadFocused="focusScratchpad"
        />
      </v-list>
    </v-col>
  </v-row>
</template>

<script>
import Clue from "@/components/grid/Clue.vue";

export default {
  components: { Clue },
  computed: {
    assignedFocus: {
      get() {
        return this.$store.state.PUZZLE.assignedFocus;
      },
      set(af) {
        this.$store.commit("PUZZLE/ASSIGNED_FOCUS_SET", af);
      },
    },
    clueOfSelected() {
      return this.$store.getters["PUZZLE/CLUE_OF_SELECTED"];
    },
    clues() {
      return this.$store.state.PUZZLE.clues;
    },
    mobileKeypress() {
      return this.$store.state.USER.mobileKeypress;
    },
    scratches() {
      return this.$store.state.PUZZLE.scratches;
    },
  },
  methods: {
    focusScratchpad(ordinal) {
      const cells = document.querySelectorAll(".bb-cell");
      for (let i = 0; i < cells.length; i++) {
        cells[i].classList.remove("selected");
      }
      this.assignedFocus = "scratchpad-" + ordinal;
    },
    handleLetter(letter) {
      if (this.assignedFocus != "grid") {
        let split = this.assignedFocus.split("-");
        let ordinal = split[1];
        let scratch = this.scratches[ordinal];

        if (typeof scratch === "undefined") {
          scratch = "";
        }

        if (/[a-zA-Z]/.test(letter.toUpperCase()) && letter.length == 1) {
          this.$store.commit("PUZZLE/SCRATCH_SET", {
            ordinal: ordinal,
            text: scratch + letter,
          });
        } else if (["del"].includes(letter) && scratch.length > 0) {
          let setVal = "";
          if (scratch.length - 1 > 0) {
            setVal = scratch.substr(0, scratch.length - 1);
          }
          this.$store.commit("PUZZLE/SCRATCH_SET", {
            ordinal: ordinal,
            text: setVal,
          });
        }
      }
    },
  },
  watch: {
    mobileKeypress() {
      if (this.mobileKeypress) {
        this.handleLetter(this.mobileKeypress);
      }
    },
  },
};
</script>

<style scoped>
.sticky-clue {
  position: sticky;
  position: -webkit-sticky;
  top: 48px;
  z-index: 6;
}
</style>
