<template>
  <v-col id="grid-container" cols="10" sm="6" lg="5" xl="4" class="py-0">
    <v-row v-if="example && $vuetify.breakpoint.xsOnly">
      <v-col cols="12" class="d-flex justify-center">
        <a
          @click="showInstructions"
          class="text-center"
          :style="{
            color: dark ? 'white' : 'black !important',
            'font-size': '12px',
            'text-decoration': 'underline',
          }"
        >
          No tutorial popping up? Click here.
        </a>
      </v-col>
    </v-row>
    <div class="table-combiner">
      <v-img
        v-if="dimension == 5"
        width="100%"
        height="100%"
        class="arrows"
        :src="require('@/assets/blockables-arrows.svg')"
        :style="{
          transform: arrowTransform,
          '-webkit-transform': arrowTransform,
          display: arrowClass == 'hidden' ? 'none' : 'initial',
        }"
      />
      <v-img
        v-if="dimension == 3"
        width="100%"
        height="100%"
        class="arrows"
        :src="require('@/assets/blockables-arrows-3x3.png')"
        :style="{
          transform: arrowTransform,
          '-webkit-transform': arrowTransform,
          display: arrowClass == 'hidden' ? 'none' : 'initial',
        }"
      />
      <table id="skeleton" class="bb-table">
        <tr v-for="(i, r) in dimension" :key="r">
          <td v-for="(j, c) in dimension" :key="c" class="bb-number-cell">
            <div class="bb-number-cell-absolute-positioner">
              <strong class="number-box">
                {{ renderNumber(r, c) }}
              </strong>
              <v-icon
                v-if="
                  r == Math.floor(dimension / 2) &&
                  c == Math.floor(dimension / 2)
                "
                id="arrow-block"
                :class="`bb-arrow bb-arrow-${direction}-${dimension}`"
              >
                {{ icons[direction] }}
              </v-icon>
              <strong
                class="check-x"
                v-html="checks.length ? renderCheck(r, c) : '&nbsp;'"
              />
            </div>
          </td>
        </tr>
      </table>
      <table id="play-area" class="bb-table">
        <tr v-for="(i, r) in dimension" :key="`r-${r}`">
          <td
            v-for="(j, c) in dimension"
            :id="`r${r}c${c}`"
            :key="`c-${c}`"
            :tutorial="
              example ? `example-cell-${exampleStepper}-r${r}c${c}` : false
            "
            class="bb-cell unselectable"
            autocomplete="off"
            :contenteditable="$vuetify.breakpoint.smAndUp"
            :readonly="true"
            inputmode="none"
            :style="{ backgroundColor: cellBackgroundStyle(r, c) }"
            @focus="$store.commit('PUZZLE/SELECT', { r: r, c: c })"
            @click="$store.commit('PUZZLE/SELECT', { r: r, c: c })"
            @keydown="handleAllKeydowns"
            @dragstart="preventDefault"
            @selectstart="preventDefault"
          />
        </tr>
      </table>
    </div>
    <v-row class="mt-0" v-if="!example || exampleStepper >= 1">
      <v-col cols="12" class="d-flex justify-center pa-0 mb-3">
        <v-btn-toggle
          v-model="arrowClass"
          :tutorial="
            example ? `example-cell-${exampleStepper}-directions` : false
          "
          tile
          :color="$colors.blue"
          group
        >
          <v-btn value="clockwise" :dark="dark" class="spiral-button">
            Clockwise
          </v-btn>
          <v-btn value="counterclockwise" :dark="dark" class="spiral-button">
            Counter
          </v-btn>
          <v-btn value="hidden" :dark="dark" class="spiral-button">Hide</v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <v-row v-if="example || unwindSpiral" class="mt-0">
      <v-col
        cols="12"
        class="d-flex justify-center pt-0 px-0 mb-0 unselectable"
      >
        <UnwoundSpiral
          :tutorial="
            example ? `example-cell-${exampleStepper}-unwound-spiral` : false
          "
        />
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import {
  mdiArrowLeftBold,
  mdiArrowUpBold,
  mdiArrowRightBold,
  mdiArrowDownBold,
} from "@mdi/js";
import UnwoundSpiral from "./UnwoundSpiral.vue";

export default {
  components: {
    UnwoundSpiral,
  },
  data: () => ({
    icons: {
      down: mdiArrowDownBold,
      left: mdiArrowLeftBold,
      right: mdiArrowRightBold,
      up: mdiArrowUpBold,
    },
  }),
  computed: {
    arrowClass: {
      get() {
        return this.$store.state.PUZZLE.arrowClass;
      },
      set(ac) {
        this.$store.commit("PUZZLE/ARROW_CLASS_SET", ac);
      },
    },
    arrowTransform() {
      let retval = "";
      if (this.arrowClass != "hidden") {
        let arrowConfig = {
          clockwise: "scaleY(1)",
          counterclockwise: "scaleY(-1)",
        };
        retval = arrowConfig[this.arrowClass];
      }
      let directionConfig = {
        right: "rotate(0deg)",
        down: "rotate(90deg)",
        left: "rotate(180deg)",
        up: "rotate(270deg)",
      };
      return `${directionConfig[this.direction]} ${retval}`;
    },
    assignedFocus() {
      return this.$store.state.PUZZLE.assignedFocus;
    },
    assistPiece() {
      return this.$store.state.USER.assists.piece;
    },
    checkCount() {
      return this.$store.state.USER.assists.checks;
    },
    checks() {
      return this.$store.state.PUZZLE.checks;
    },
    clueOfSelected() {
      return this.$store.getters["PUZZLE/CLUE_OF_SELECTED"];
    },
    clues() {
      return this.$store.state.PUZZLE.clues;
    },
    colorCounts() {
      return this.$store.getters["USER/COLOR_COUNTS"](this.gridColors);
    },
    colorless() {
      return this.$store.state.USER.settings.colorless;
    },
    confirmedPuzzleReveal() {
      return this.$store.state.USER.confirmedPuzzleReveal;
    },
    dark() {
      return this.$store.state.USER.settings.dark;
    },
    dimension() {
      return this.$store.state.PUZZLE.dimension;
    },
    direction() {
      return this.$store.state.PUZZLE.direction;
    },
    example() {
      return this.$route.name == "How to Play";
    },
    exampleStepper() {
      return this.$store.state.USER.exampleStepper;
    },
    gameStarted() {
      return this.$store.state.USER.gameStarted;
    },
    gridColors() {
      return this.$store.state.PUZZLE.gridColors;
    },
    isMobile() {
      return this.$store.getters["USER/IS_MOBILE"];
    },
    isProd() {
      return process.env.VUE_APP_ENVIRONMENT === "prod";
    },
    mobileKeypress() {
      return this.$store.state.USER.mobileKeypress;
    },
    numberPlacements() {
      return this.$store.state.PUZZLE.numberPlacements;
    },
    playAgainCount() {
      return this.$store.state.USER.playAgainCount;
    },
    playerGrid() {
      return this.$store.state.PUZZLE.playerGrid;
    },
    reveals() {
      return this.$store.state.USER.assists.reveals;
    },
    selected() {
      return this.$store.state.PUZZLE.selected;
    },
    unwindSpiral() {
      return this.$store.state.USER.settings.unwindSpiral;
    },
    user() {
      return !this.$ark.active && this.$store.state.sb_user;
    },
    winByGrid() {
      return this.$store.getters["PUZZLE/WIN_BY_GRID"];
    },
    winGrid() {
      return this.$store.state.PUZZLE.winGrid;
    },
  },
  watch: {
    checkCount: {
      handler(newVal) {
        if (newVal == 0) return;
        let check = this.assistPiece;
        this.$analytics.logEvent("assist_used", {
          type: check,
          clue: this.clueOfSelected.clue,
        });
        if (
          check.includes("letter") &&
          this.playerGrid[this.selected.r][this.selected.c]
        ) {
          let correctLetter =
            this.winGrid[this.selected.r][this.selected.c].toUpperCase();
          let playerLetter =
            this.playerGrid[this.selected.r][this.selected.c].toUpperCase();
          this.$store.commit("PUZZLE/POPULATE_CHECK", {
            check: correctLetter != playerLetter,
            r: null,
            c: null,
          });
          return;
        } else if (check.includes("word-in-grid")) {
          let colorToReveal = this.clueOfSelected.color;
          for (let r = 0; r < this.dimension; r++) {
            for (let c = 0; c < this.dimension; c++) {
              if (
                this.gridColors[r][c] == colorToReveal &&
                this.playerGrid[r][c]
              ) {
                let playerLetter = this.playerGrid[r][c].toUpperCase();
                let correctLetter = this.winGrid[r][c].toUpperCase();
                this.$store.commit("PUZZLE/POPULATE_CHECK", {
                  check: correctLetter != playerLetter,
                  r: r,
                  c: c,
                });
              }
            }
          }
        } else if (check.includes("puzzle")) {
          for (let r = 0; r < this.dimension; r++) {
            for (let c = 0; c < this.dimension; c++) {
              if (this.playerGrid[r][c]) {
                let playerLetter = this.playerGrid[r][c].toUpperCase();
                let correctLetter = this.winGrid[r][c].toUpperCase();
                this.$store.commit("PUZZLE/POPULATE_CHECK", {
                  check: correctLetter != playerLetter,
                  r: r,
                  c: c,
                });
              }
            }
          }
        }
      },
      deep: true,
    },
    async confirmedPuzzleReveal() {
      if (this.confirmedPuzzleReveal) {
        for (let r = 0; r < this.dimension; r++) {
          for (let c = 0; c < this.dimension; c++) {
            let playerLetter = this.playerGrid[r][c];
            if (
              !playerLetter ||
              playerLetter.toUpperCase() != this.winGrid[r][c].toUpperCase()
            ) {
              await this.$store.dispatch("USER/COORDS_REVEAL_APPEND", {
                r: r,
                c: c,
              });
            }
            this.insertLetter(this.winGrid[r][c], r, c);
          }
        }
        this.$store.commit("USER/PUZZLE_DIALOG_SET", 0);
      }
    },
    dimension() {
      this.$nextTick(() => {
        this.styleDynamicHeight();
      });
    },
    mobileKeypress() {
      if (this.mobileKeypress) {
        this.handleLetter(
          this.mobileKeypress,
          this.selected.r,
          this.selected.c
        );
      }
    },
    playAgainCount() {
      for (let r = 0; r < this.dimension; r++) {
        for (let c = 0; c < this.dimension; c++) {
          this.insertLetter(null, r, c);
        }
      }
    },
    playerGrid: {
      handler() {
        if (
          this.$ark.active &&
          process.env.VUE_APP_DEPLOYMENT_PLATFORM == "arkadium"
        ) {
          if (
            this.playerGrid.every(row => row.every(element => element !== null))
          ) {
            this.$ark.api.handleEventChange();
          }
        }
      },
      deep: true,
    },
    reveals: {
      async handler(newVal) {
        if (newVal == 0) return;
        let reveal = this.assistPiece;
        this.$analytics.logEvent("assist_used", {
          type: reveal,
          clue: this.clueOfSelected.clue,
        });
        if (reveal.includes("letter")) {
          let correctLetter = this.winGrid[this.selected.r][this.selected.c];
          let playerLetter = this.playerGrid[this.selected.r][this.selected.c];
          if (
            !playerLetter ||
            playerLetter.toUpperCase() != correctLetter.toUpperCase()
          ) {
            await this.$store.dispatch(
              "USER/COORDS_REVEAL_APPEND",
              this.selected
            );
          }
          this.insertLetter(correctLetter);
          return;
        } else if (reveal.includes("word") || reveal.includes("word-in-grid")) {
          await this.$store.dispatch("PUZZLE/REVEAL_WORD", {
            clueOfSelected: this.clueOfSelected,
          });
          let colorToReveal = this.clueOfSelected.color;
          for (let r = 0; r < this.dimension; r++) {
            for (let c = 0; c < this.dimension; c++) {
              if (this.gridColors[r][c] == colorToReveal) {
                let correctLetter = this.winGrid[r][c];
                let playerLetter = this.playerGrid[r][c];
                if (
                  !playerLetter ||
                  playerLetter.toUpperCase() != correctLetter.toUpperCase()
                ) {
                  await this.$store.dispatch("USER/COORDS_REVEAL_APPEND", {
                    r: r,
                    c: c,
                  });
                }
                if (reveal.includes("word-in-grid")) {
                  this.insertLetter(this.winGrid[r][c], r, c);
                }
              }
            }
          }
        } else if (reveal.includes("puzzle") && !this.confirmedPuzzleReveal) {
          this.$store.commit("USER/PUZZLE_DIALOG_SET", 1);
          return;
        }
      },
      deep: true,
    },
    selected: {
      handler() {
        if (this.assignedFocus == "grid") {
          this.clearSelected();
          let selectedCell = document.getElementById(
            `r${this.selected.r}c${this.selected.c}`
          );
          selectedCell.classList.add("selected");
          let clueId = `clue-${this.clueOfSelected.ordinal}`;
          let clueElement = document.getElementById(clueId);
          if (!clueElement) return;
          clueElement.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "nearest",
          });
        }
      },
      deep: true,
    },
    winByGrid() {
      if (this.winByGrid) {
        this.$store.commit(
          `USER/${this.example ? "EXAMPLE_" : ""}WIN_DIALOG_SET`,
          true
        );
      }
    },
  },
  mounted() {
    if (this.$route.hash.includes("error_description")) {
      this.$store.commit("USER/SNACKBAR_SET", {
        type: "bad",
        active: true,
        text: "ERROR: " + this.$route.hash,
      });
    }
    this.$nextTick(() => {
      let selectedCell = document.getElementById(
        `r${this.selected.r}c${this.selected.c}`
      );
      selectedCell.classList.add("selected");
      this.styleDynamicHeight();
    });
  },
  created() {
    window.addEventListener("resize", this.styleDynamicHeight);
    if (this.mobile) {
      // eslint-disable-next-line vue/valid-next-tick
      ScreenOrientation.onchange = this.$nextTick(() => {
        this.styleDynamicHeight();
      });
    }
  },
  destroyed() {
    this.$store.commit("USER/TIMER_STOP");
  },
  methods: {
    showInstructions() {
      this.$store.commit("USER/INSTRUCTIONS_DIALOG_SET", true);
    },
    clearSelected() {
      const cells = document.querySelectorAll(".bb-cell");
      for (let i = 0; i < cells.length; i++) {
        cells[i].classList.remove("selected");
      }
    },
    preventDefault($event) {
      $event.preventDefault();
    },
    handleAllKeydowns($event) {
      let moveEvents = ["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"];
      if (this.selected.r === null || this.selected.c === null) return;
      if (moveEvents.includes($event.key)) {
        let newR = this.selected.r || 0;
        let newC = this.selected.c || 0;

        switch ($event.key) {
          case "ArrowUp":
            newR = newR - 1 < 0 ? 0 : newR - 1;
            break;
          case "ArrowDown":
            newR = newR + 1 > 4 ? 4 : newR + 1;
            break;
          case "ArrowLeft":
            newC = newC - 1 < 0 ? 0 : newC - 1;
            break;
          case "ArrowRight":
            newC = newC + 1 > 4 ? 4 : newC + 1;
            break;
        }

        let id = "r" + newR + "c" + newC;
        document.getElementById(id).focus();
        return;
      }

      if ($event.key != "Tab") {
        $event.preventDefault();
      }

      if (this.won()) {
        this.$store.commit("USER/MOBILE_KEYPRESS_SET", null);
        return;
      }

      if (
        ($event.keyCode >= 65 && $event.keyCode <= 90) ||
        ($event.keyCode >= 97 && $event.keyCode <= 123)
      ) {
        this.handleLetter($event.key, this.selected.r, this.selected.c);
        return;
      } else if (["Backspace", "Delete"].includes($event.key)) {
        this.handleLetter("del", this.selected.r, this.selected.c);
      }
    },
    renderCheck(r, c) {
      return this.checks[r][c];
    },
    won() {
      return this.winByGrid;
    },
    styleDynamicHeight() {
      let grids = document.querySelectorAll(".bb-table");
      for (let i = 0; i < grids.length; i++) {
        grids[i].style.height = getComputedStyle(grids[i]).width;
      }

      let cells = document.querySelectorAll(".bb-cell");
      for (let i = 0; i < cells.length; i++) {
        cells[i].style.height = getComputedStyle(cells[i]).width;
      }

      let boxes = document.querySelectorAll(
        ".bb-number-cell-absolute-positioner"
      );
      for (let i = 0; i < boxes.length; i++) {
        boxes[i].style.height = getComputedStyle(boxes[i]).width;
      }

      let tableCombiner = document.querySelector(".table-combiner");
      tableCombiner.style.height = getComputedStyle(tableCombiner).width;

      const doc = document.documentElement;
      doc.style.setProperty("--grid-height", tableCombiner.style.height);
    },
    cellBackgroundStyle(r, c) {
      if (!this.gridColors) return;
      if (this.colorless) return this.dark ? "#999999" : "white";
      return this.gridColors[r][c];
    },
    getOrdinalFromColor(color) {
      return Object.keys(this.colorCounts).indexOf(color) + 1;
    },
    async handleKeydown($event) {
      let moveEvents = ["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"];
      if (this.selected.r === null || this.selected.c === null) return;
      if (moveEvents.includes($event.key)) {
        let newR = this.selected.r || 0;
        let newC = this.selected.c || 0;

        switch ($event.key) {
          case "ArrowUp":
            newR = newR - 1 < 0 ? 0 : newR - 1;
            break;
          case "ArrowDown":
            newR = newR + 1 > 4 ? 4 : newR + 1;
            break;
          case "ArrowLeft":
            newC = newC - 1 < 0 ? 0 : newC - 1;
            break;
          case "ArrowRight":
            newC = newC + 1 > 4 ? 4 : newC + 1;
            break;
        }

        let id = "r" + newR + "c" + newC;
        document.getElementById(id).focus();
        return;
      }

      if ($event.key != "Tab") {
        $event.preventDefault();
      }

      if (this.won()) {
        this.$store.commit("USER/MOBILE_KEYPRESS_SET", null);
        return;
      }

      if (
        ($event.keyCode >= 65 && $event.keyCode <= 90) ||
        ($event.keyCode >= 97 && $event.keyCode <= 123)
      ) {
        this.insertLetter($event.key);
        return;
      } else if (["Backspace", "Delete"].includes($event.key)) {
        document.getElementById(
          "r" + this.selected.r + "c" + this.selected.c
        ).innerText = "";
        this.$store.commit("PUZZLE/INSERT_LETTER_INTO_PLAYER_GRID", {
          letter: null,
          r: this.selected.r,
          c: this.selected.c,
        });
        try {
          await this.$store.dispatch("LOCAL_STORAGE_SET", {
            id: "blockables-grid",
            value: JSON.stringify(this.playerGrid),
          });
        } catch {
          // localstorage inaccessible, ignore it
        }
      }
    },
    async handleLetter(letter, r, c) {
      if (this.won()) {
        this.$store.commit("USER/MOBILE_KEYPRESS_SET", null);
        return;
      }
      if (r === null || c === null) return;

      if (this.assignedFocus == "grid") {
        let id = "r" + r + "c" + c;

        if (/[a-zA-Z]/.test(letter) && letter.length == 1) {
          this.insertLetter(letter, r, c);
        } else if (["del"].includes(letter)) {
          document.getElementById(id).innerText = "";
          this.$store.commit("PUZZLE/INSERT_LETTER_INTO_PLAYER_GRID", {
            letter: null,
            r: r,
            c: c,
          });
          try {
            await this.$store.dispatch("LOCAL_STORAGE_SET", {
              id: "blockables-grid",
              value: JSON.stringify(this.playerGrid),
            });
          } catch {
            // localstorage inaccessible, ignore it
          }
        }

        document.getElementById(id).focus();
      }
      this.$store.commit("USER/MOBILE_KEYPRESS_SET", null);
    },
    focusScratchpad(ordinal) {
      this.clearSelected();
      this.$store.commit("PUZZLE/ASSIGNED_FOCUS_SET", "scratchpad-" + ordinal);
    },
    async insertLetter(letter, _r = null, _c = null) {
      let r = _r !== null ? _r : this.selected.r;
      let c = _c !== null ? _c : this.selected.c;
      this.$store.commit("PUZZLE/POPULATE_CHECK", { check: false, r: r, c: c });
      document.getElementById("r" + r + "c" + c).innerText = letter;
      this.$store.commit("PUZZLE/INSERT_LETTER_INTO_PLAYER_GRID", {
        letter: letter,
        r: r,
        c: c,
      });
      try {
        await this.$store.dispatch("LOCAL_STORAGE_SET", {
          id: "blockables-grid",
          value: JSON.stringify(this.playerGrid),
        });
      } catch {
        // localstorage inaccessible, ignore it
      }
    },
    renderNumber(r, c) {
      if (this.gridColors) {
        if (this.colorless) {
          let color = this.gridColors[r][c];
          let clue = this.clues.filter(c => c.color == color)[0];
          for (const k in this.numberPlacements) {
            let v = this.numberPlacements[k];
            if (v[0] == r && v[1] == c) {
              return clue.ordinal + "*";
            }
          }
          return clue.ordinal;
        }
        for (const k in this.numberPlacements) {
          let v = this.numberPlacements[k];
          if (v[0] == r && v[1] == c) {
            return k;
          }
        }
        return "";
      }
    },
  },
};
</script>

<style scoped>
.bb-table {
  border-collapse: collapse;
  z-index: 0;
  position: absolute;
  width: 100%;
  margin: 0;
}

.table-combiner {
  position: relative;
}

#skeleton {
  z-index: 1;
  pointer-events: none;
}

.clockwise {
  transform: scaleY(1);
  -webkit-transform: scaleY(1);
}

.counterclockwise {
  transform: scaleY(-1);
  -webkit-transform: scaleY(-1);
}

.hidden {
  display: none;
}

.arrows {
  position: absolute;
  z-index: 3;
  opacity: 0.25;
  pointer-events: none;
  border: 1px solid transparent;
}

.arrows-right {
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
}

.arrows-down {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
}

.arrows-left {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}

.arrows-up {
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
}

.selected {
  box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.75) inset;
  -webkit-box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.75) inset;
  -moz-box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.75) inset;
}

.bb-cell {
  width: 20%;
  vertical-align: middle;
  text-transform: uppercase;
  border: 2px solid black;
  color: black;
  font-size: 3.8vw;
  text-align: center;
  caret-color: transparent;
  cursor: pointer;
}

.bb-cell:focus {
  box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.75) inset;
  -webkit-box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.75) inset;
  -moz-box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.75) inset;
}

.number-box {
  position: absolute;
  padding: 0 5px;
  font-size: 20px;
}

.check-x {
  padding: 0 5px;
  font-size: 20px;
  float: right;
}

@media (max-width: 599px) {
  .number-box {
    font-size: 4vw;
  }

  .check-x {
    font-size: 5vw;
  }

  .table-combiner {
    height: 100vw;
  }

  .bb-table {
    height: 90vw;
  }

  .bb-cell {
    font-size: 9vw;
  }

  .bb-arrow {
    font-size: 8vw !important;
  }
}

@media (min-width: 1600px) {
  .bb-cell {
    font-size: 2.8vw;
  }
}

[contenteditable] {
  outline: 0px solid transparent;
}

.grey-border {
  border: 1px #333 solid;
}

.bb-number-cell {
  width: 20%;
  vertical-align: text-top;
  background-color: rgba(0, 0, 0, 0);
  /* border: 2px solid black; */
  color: black;
  font-size: 20px;
}

.bb-number-cell-absolute-positioner {
  position: absolute;
  width: 20%;
}

.bb-arrow {
  position: absolute;
  color: black !important;
  width: 50%;
  height: 50%;
}

.bb-arrow-up-3 {
  margin-top: -35%;
  margin-left: 58.5%;
}

.bb-arrow-down-3 {
  margin-top: 150%;
  margin-left: 58.5%;
}

.bb-arrow-left-3 {
  margin-top: 55%;
  margin-left: -30%;
}

.bb-arrow-right-3 {
  margin-top: 55%;
  margin-left: 150%;
}

.bb-arrow-up-5 {
  margin-top: -35%;
  margin-left: 25%;
}

.bb-arrow-down-5 {
  margin-top: 80%;
  margin-left: 25%;
}

.bb-arrow-left-5 {
  margin-top: 25%;
  margin-left: -30%;
}

.bb-arrow-right-5 {
  margin-top: 25%;
  margin-left: 80%;
}

*.unselectable {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;

  /*
     Introduced in IE 10.
     See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
   */
  -ms-user-select: none;
  user-select: none;
}

.spiral-button {
  height: 24px !important;
  padding: 0 6px !important;
}
</style>
