<template>
  <v-dialog :dark="dark" :value="active" width="500" persistent>
    <v-card style="overflow: hidden">
      <v-row>
        <v-spacer />
        <v-col cols="10" class="justify-center text-center">
          <v-icon
            class="win-close-icon"
            @click="$store.commit('USER/WIN_DIALOG_SET', false)"
          >
            {{ icons.close }}
          </v-icon>
          <h1 v-if="fastWin" style="font-size: 100px" class="fast-swing">
            {{ speedEmoji }}
          </h1>
          <h1 v-else style="font-size: 100px" class="swing">🎉</h1>
          <br />
          <p
            v-if="timed && speedEmoji == '👑'"
            style="font-size: 30px; margin-bottom: 20px"
          >
            Behold, you are
            <br />
            <strong>Blockables Royalty!</strong>
          </p>
          <p v-if="timed && speedEmoji == '👑'">
            You bested this measly puzzle in a mere
            <strong>{{ timeInSeconds }} seconds!</strong>
          </p>
          <p
            v-if="timed && speedEmoji == '🎷'"
            style="font-size: 30px; margin-bottom: 20px"
          >
            Dang, you are
            <strong>fast!</strong>
            <br />
            You solved Blockable #{{ puzzleNumber }} in
            <strong>{{ timeInHumanWords }}!</strong>
          </p>
          <p
            v-if="timed && !fastWin && !example"
            style="font-size: 30px; margin-bottom: 20px"
          >
            You solved Blockable #{{ puzzleNumber }} in
            <strong>{{ timeInHumanWords }}.</strong>
          </p>
          <p v-if="example" style="font-size: 30px; margin-bottom: 20px">
            You solved the example Blockable!
          </p>
          <p
            v-if="!timed && !example"
            style="font-size: 30px; margin-bottom: 20px"
          >
            You solved Blockable #{{ puzzleNumber }}!
          </p>
          <p v-if="!example" class="pt-0">
            Blockable #{{ puzzleNumber }} Answer:
            <br />
            <strong>{{ phrase }}</strong>
          </p>
          <p v-else>
            Example Blockable Answer:
            <br />
            <strong>{{ phrase }}</strong>
          </p>
          <template v-if="!example">
            <p v-if="!isCoolMath">
              Tomorrow's puzzle in:
              <b>{{ timeRemaining }}...</b>
            </p>
            <v-row>
              <v-col cols="12" class="d-flex align-center justify-center">
                <v-btn
                  v-if="puzzleDate == today"
                  block
                  :color="$colors.blue"
                  class="white--text"
                  @click="submitScore"
                >
                  Submit Score
                </v-btn>
                <v-btn
                  v-else-if="isCoolMath"
                  block
                  :color="$colors.blue"
                  class="white--text"
                  @click="playNextPuzzle"
                >
                  Play Next Puzzle
                </v-btn>
                <v-btn
                  v-else
                  block
                  :color="$colors.blue"
                  class="white--text"
                  @click="triggerMidroll"
                >
                  Play Another Puzzle
                </v-btn>
              </v-col>
            </v-row>
          </template>
          <template v-else>
            <p>Ready to try the real thing?</p>
            <v-btn
              block
              :color="$colors.green"
              class="white--text"
              @click="navToHome"
            >
              Play Today's Blockable
            </v-btn>
          </template>
          <br />
        </v-col>
        <v-spacer />
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClose } from "@mdi/js";

// import PlayPastPuzzles from "./PlayPastPuzzles.vue";

export default {
  // components: { PlayPastPuzzles },
  data: () => ({
    fastWin: false,
    icons: {
      close: mdiClose,
    },
    pppActive: false,
    scoreSaved: false,
    tomorrow: null,
  }),
  computed: {
    active() {
      return this.$store.state.USER.dialogs.win;
    },
    checks() {
      return this.$store.state.USER.assists.checks;
    },
    coordsRevealed() {
      return this.$store.state.USER.assists.coordsRevealed.length;
    },
    dark() {
      return this.$store.state.USER.settings.dark;
    },
    example() {
      return this.$route.name == "How to Play";
    },
    helperEmojis() {
      return this.$store.getters["USER/HELPER_EMOJIS"];
    },
    isCoolMath() {
      return process.env.VUE_APP_DEPLOYMENT_PLATFORM == "coolmath";
    },
    phrase() {
      return this.$store.state.PUZZLE.phrase;
    },
    puzzleDate() {
      return this.$store.state.PUZZLE.metadata.date;
    },
    puzzleId() {
      return this.$store.state.PUZZLE.metadata.puzzleId;
    },
    puzzleNumber() {
      return this.$store.state.PUZZLE.metadata.puzzleNumber;
    },
    record() {
      let textMap = {
        "👑": "crown",
        "🎷": "sax",
        "🎉": "tada",
      };
      return {
        checks: this.checks,
        letters_revealed: this.coordsRevealed,
        puzzle_id: this.puzzleId,
        puzzle_ordinal: this.puzzleNumber,
        symbol: textMap[this.speedEmoji],
        timed: this.timed,
      };
    },
    silent() {
      return this.$store.state.USER.settings.silent;
    },
    speedEmoji() {
      return this.$store.getters["USER/GET_SPEED_EMOJI"];
    },
    timed() {
      return !this.$store.state.USER.settings.untimed;
    },
    timer() {
      return this.$store.getters["USER/GET_TIME"](null);
    },
    timeInHumanWords() {
      let solve = this.timer;
      let split = this.timer.split(":");
      let minutes = parseInt(split[0]);
      let seconds = parseInt(split[1]);
      if (minutes == "0") {
        solve = seconds + " seconds";
      }
      return solve;
    },
    timeRemaining() {
      return this.$store.getters["USER/TIME_REMAINING"];
    },
    timeInSeconds() {
      return this.$store.state.USER.timer;
    },
    today() {
      return this.$store.getters["USER/TODAY"](this.$ark.active);
    },
  },
  watch: {
    active: {
      async handler() {
        if (this.active) {
          let time = null;
          try {
            time =
              this.timeInSeconds ||
              (await this.$store.dispatch(
                "LOCAL_STORAGE_GET",
                "blockables-time"
              ));
          } catch (e) {
            time = this.timeInSeconds;
          }
          this.$store.commit("USER/PLAYING_SET", false);
          this.$store.commit("USER/TIMER_STOP");
          this.$store.commit("USER/MOBILE_KEYPRESS_SET", null); // reset keys
          this.fastWin = await this.$store.dispatch("USER/FAST_WIN");
          this.$store.commit("USER/COUNTDOWN_START");
          await this.logWin();
          try {
            if (
              !(await this.$store.dispatch(
                "LOCAL_STORAGE_GET",
                "blockables-won-today"
              ))
            ) {
              let completedToday = false;
              let totalRecord = {
                time: time,
                ...this.record,
              };
              await this.$store.dispatch("LOCAL_STORAGE_SET", {
                id: "blockables-record",
                value: JSON.stringify(totalRecord),
              });
              if (!this.silent && !completedToday) {
                this.$store.state.USER.audio.volume = 0.4;
                let celebrate = require("@/assets/sounds/tada.mp3");
                if (this.record.symbol == "crown") {
                  celebrate = require("@/assets/sounds/crown.mp3");
                } else if (this.record.symbol == "sax") {
                  celebrate = require("@/assets/sounds/sax.mp3");
                }
                this.$store.state.USER.audio.src = celebrate;
                this.$store.state.USER.audio.play();
              }
              if (!this.example) {
                await this.$store.dispatch("LOCAL_STORAGE_SET", {
                  id: "blockables-won-today",
                  value: 1,
                });
              }
            }
          } catch {
            if (!this.silent) {
              this.$store.state.USER.audio.volume = 0.4;
              let celebrate = require("@/assets/sounds/tada.mp3");
              if (this.record.symbol == "crown") {
                celebrate = require("@/assets/sounds/crown.mp3");
              } else if (this.record.symbol == "sax") {
                celebrate = require("@/assets/sounds/sax.mp3");
              }
              this.$store.state.USER.audio.src = celebrate;
              this.$store.state.USER.audio.play();
            }
          }
          this.$analytics.logEvent("puzzle_complete", {
            checks: this.checks,
            letters_revealed: this.coordsRevealed,
            time: this.timeInSeconds,
          });
        }
      },
      immediate: true,
    },
  },
  methods: {
    async logWin() {
      try {
        let wins = await this.$store.dispatch(
          "LOCAL_STORAGE_GET",
          "blockables-ark-wins"
        );
        if (!wins) {
          wins = [this.puzzleNumber];
        } else {
          wins = JSON.parse(wins);
          if (!wins.length) {
            wins = [this.puzzleNumber];
          } else {
            wins.push(this.puzzleNumber);
          }
        }
        await this.$store.dispatch("LOCAL_STORAGE_SET", {
          id: "blockables-ark-wins",
          value: JSON.stringify(wins),
        });
        await this.$store.dispatch("USER/ELIMINATE_LOCAL_STORAGE");
      } catch (e) {
        // localstorage inaccessible, ignore it
        console.log(e);
      }
    },
    async navToHome() {
      await this.$store.dispatch("USER/REINITIALIZE");
      this.$store.commit("USER/INCREMENT_PLAY_AGAIN_COUNTER");
      this.$store.commit("USER/WIN_DIALOG_SET", false);
      await this.$store.dispatch("USER/TIMER_START");
      this.$store.commit("USER/EXAMPLE_SET", false);
      this.$store.commit("USER/GAME_START", false);
      this.$router.push("/");
    },
    async submitScore() {
      let time = null;
      try {
        time =
          this.timeInSeconds ||
          (await this.$store.dispatch("LOCAL_STORAGE_GET", "blockables-time"));
      } catch {
        time = this.timeInSeconds;
      }
      if (this.$ark.active) {
        let score = 3600;
        score -= time;
        score -= this.checks * 25;
        score -= this.coordsRevealed * 100;
        if (score < 0) {
          score = 0;
        }
        if (process.env.VUE_APP_DEPLOYMENT_PLATFORM == "arkadium-sdk") {
          await this.$ark.api.callAsyncSDKFunction("onChangeScore", {
            score: score,
          });
          await this.$ark.api.callAsyncSDKFunction("onGameEnd");
        }
        if (process.env.VUE_APP_DEPLOYMENT_PLATFORM == "arkadium") {
          this.$ark.api.handleGameEnd(score, "");
        }

        this.$router.push("/");
        await this.$store.dispatch("USER/REINITIALIZE");
        this.$store.commit("USER/INCREMENT_PLAY_AGAIN_COUNTER");
        this.$store.commit("USER/WIN_DIALOG_SET", false);
        this.$store.commit("USER/COUNTDOWN_START");
        this.$store.commit("USER/EXAMPLE_SET", false);
        this.$store.commit("USER/GAME_START", false);
      }
    },
    async triggerMidroll() {
      if (this.$ark.active) {
        if (process.env.VUE_APP_DEPLOYMENT_PLATFORM == "arkadium") {
          this.$ark.api.handleMidrollRequest();
        }
        if (process.env.VUE_APP_DEPLOYMENT_PLATFORM == "arkadium-sdk") {
          await this.$ark.api.showAd("interstitial");
        }
      }
      if (process.env.VUE_APP_DEPLOYMENT_PLATFORM.includes("arkadium")) {
        await this.$store.dispatch("USER/REINITIALIZE");
        this.$store.commit("USER/INCREMENT_PLAY_AGAIN_COUNTER");
        this.$store.commit("USER/WIN_DIALOG_SET", false);
        this.$store.commit("USER/COUNTDOWN_START");
        this.$store.commit("USER/EXAMPLE_SET", false);
        this.$store.commit("USER/GAME_START", false);
        this.$router.push("/");
      }
    },
    playNextPuzzle() {
      window.cmgAdBreak();
      let date = new Date(
        new Date(this.$route.params.d).toLocaleString("en-US", {
          timeZone: "America/New_York",
        })
      );
      date.setDate(date.getDate() + 1);
      date = date.toISOString().split("T")[0];
      this.$router.push(`/puzzles/${date}`);
    },
  },
};
</script>

<style scoped>
.swing {
  margin: 10px 0 -10px 0;
  animation: swing ease-in-out 1s infinite alternate;
}

.win-close-icon {
  position: absolute;
  top: 25px;
  right: 40px;
  z-index: 500;
}

@keyframes swing {
  0% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(-10deg);
  }
}

.fast-swing {
  animation: swing ease-in-out 0.5s infinite alternate;
}

@keyframes swing {
  0% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(-15deg);
  }
}
</style>
