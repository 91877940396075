<template>
  <Puzzle :date="date" />
</template>

<script>
import Puzzle from "@/components/Puzzle.vue";

export default {
  name: "Example",
  components: {
    Puzzle,
  },
  computed: {
    date() {
      return this.$store.state.USER.date;
    },
  },
  async beforeMount() {
    this.$analytics.logEvent("tutorial_begin");
    this.$store.commit("USER/DATE_SET", "0003-03-03");
    this.$store.commit("USER/EXAMPLE_SET", true);
    await this.$store.dispatch("USER/CLEAR_LOCAL_STORAGE");
    try {
      await this.$store.dispatch("PUZZLE/FETCH", {
        date: this.date,
        platform: process.env.VUE_APP_DEPLOYMENT_PLATFORM,
      });
    } catch (e) {
      console.log(e);
      this.$store.commit("USER/SNACKBAR_SET", {
        active: true,
        type: "bad",
        text: "003 Blockables was unable to contact the server. Please check your connection or try again later.",
      });
    }
  },
  mounted() {
    this.$tour.start("0");
    if (this.$vuetify.breakpoint.xsOnly) {
      this.$tour.tourSteps[1] = {
        content:
          "This is where clues appear. The answers to clues go in the grid. The answer to the first clue is 'FWD'.",
        title: "The Clues",
        target: ".sticky-clue",
        step: "2",
        group: "0",
      };
    }
  },
  destroyed() {
    this.$store.commit("USER/EXAMPLE_SET", false);
  },
};
</script>

<style scoped></style>
