<template>
  <v-dialog :value="active" width="350" :dark="dark" @click:outside="close">
    <v-card :dark="dark" style="height: 500px">
      <v-card-title>Select a puzzle to play!</v-card-title>
      <v-card-subtitle>
        <v-checkbox
          v-model="onlyIncomplete"
          label="Show only incomplete puzzles"
          hide-details
          :color="$colors.blue"
        />
      </v-card-subtitle>
      <v-card-text id="custom-scroll">
        <span v-if="loading">Loading puzzles...</span>
        <v-list v-else v-for="item in paginatedPuzzles" :key="item.ordinal">
          <v-list-item
            two-line
            :disabled="puzzleWon(item.ordinal)"
            @click="play(item.date)"
          >
            <v-list-item-content>
              <v-list-item-title>
                <strong>Blockable #{{ item.ordinal }}</strong>
              </v-list-item-title>

              <v-list-item-subtitle>
                <div>
                  <!-- <span>{{ getFullDate(item.date) }}</span> -->
                  <span>{{ item.category }}</span>
                </div>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon
                v-if="!puzzleWon(item.ordinal)"
                x-large
                :color="$colors.blue"
              >
                {{ icons.chevronRight }}
              </v-icon>
              <v-icon v-else x-large :color="$colors.blue">
                {{ icons.check }}
              </v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-col cols="6" class="d-flex justify-start">
          <v-btn icon :dark="dark" @click="updatePage(-1)">
            <v-icon>{{ icons.chevronLeft }}</v-icon>
          </v-btn>
          <span class="d-flex align-center">
            {{ page + 1 }} / {{ allPages }}
          </span>
          <v-btn icon :dark="dark" @click="updatePage(1)">
            <v-icon>{{ icons.chevronRight }}</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="6" class="d-flex justify-end">
          <v-btn text :dark="dark" @click="close">Close</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
  
  <script>
import { mdiCheck, mdiChevronLeft, mdiChevronRight } from "@mdi/js";

const OFFSET_FACTOR = 25;

export default {
  props: ["pppActive"],
  data: () => ({
    active: false,
    allPuzzles: [],
    highestPage: 0,
    icons: {
      check: mdiCheck,
      chevronLeft: mdiChevronLeft,
      chevronRight: mdiChevronRight,
    },
    onlyIncomplete: true,
    loading: false,
    page: 0,
  }),
  computed: {
    allPages() {
      return Math.ceil(this.allPuzzles.length / OFFSET_FACTOR);
    },
    dark() {
      return this.$store.state.USER.settings.dark;
    },
    emojiMap() {
      return {
        crown: "👑",
        sax: "🎷",
        tada: "🎉",
      };
    },
    paginatedPuzzles() {
      let pp = this.allPuzzles.slice(
        this.page * OFFSET_FACTOR,
        (this.page + 1) * OFFSET_FACTOR
      );
      if (this.onlyIncomplete) {
        pp = pp.filter(puz => !this.puzzleWon(puz.ordinal));
      }
      return pp;
    },
    puzzleNumber() {
      return this.$store.state.PUZZLE.metadata.puzzleNumber;
    },
    today() {
      return this.$store.getters["USER/TODAY"](this.$ark.active);
    },
    todayOrdinal() {
      return this.getOrdinal(this.today);
    },
    winByGrid() {
      return this.$store.getters["PUZZLE/WIN_BY_GRID"];
    },
  },
  watch: {
    onlyIncomplete() {
      this.allPuzzles = [];
      this.highestPage = 0;
      this.page = 0;
      this.getPuzzles();
      this.scrollToTop();
    },
    page() {
      if (this.page > this.highestPage) {
        this.highestPage = this.page;
        this.getPuzzles();
        this.scrollToTop();
      }
    },
    pppActive() {
      if (this.pppActive) {
        this.getPuzzles();
        this.active = true;
      }
    },
  },
  methods: {
    close() {
      this.active = false;
      this.$emit("modalClose");
      this.allPuzzles = [];
    },
    getFullDate(d) {
      return new Date(d).toUTCString().substring(0, 16);
    },
    getOrdinal(d) {
      let o = null;
      try {
        let debutDate = process.env.VUE_APP_DEBUT_DATE;
        let then = debutDate.split("-");
        then = new Date(then[0], then[1] - 1, then[2]);
        let now = d.split("-");
        now = new Date(now[0], now[1] - 1, now[2]);
        o = Math.round((now - then) / (1000 * 60 * 60 * 24)) + 1;
      } catch {
        o = null;
      }
      return o;
    },
    async getPuzzles() {
      this.loading = true;
      const puzzleData = await require("@/assets/puzzles.json");
      console.log(puzzleData);
      this.allPuzzles.push(...puzzleData.slice(3));
      this.loading = false;
    },
    async play(d) {
      this.$analytics.logEvent("play_past_puzzle");
      await this.$store.dispatch("USER/REINITIALIZE");
      this.$store.commit("USER/INCREMENT_PLAY_AGAIN_COUNTER");
      this.$store.commit("USER/WIN_DIALOG_SET", false);
      await this.$store.dispatch("USER/TIMER_START");
      this.$store.commit("USER/EXAMPLE_SET", false);
      this.$store.commit("USER/GAME_START", false);
      this.$router.push("/puzzles/" + d);
      this.close();
    },
    puzzleWon(ordinal) {
      try {
        let wins = localStorage.getItem("blockables-ark-wins");
        if (!wins) return false;
        return JSON.parse(wins).includes(ordinal);
      } catch {
        return false;
      }
    },
    scrollToTop() {
      let scr = document.getElementById("custom-scroll");
      scr.scrollTop = 0;
    },
    updatePage(amount) {
      let temp = this.page + amount;
      if (temp < 0) {
        this.page = 0;
        return;
      }
      if (temp >= this.allPages) {
        this.page = this.allPages - 1;
        return;
      }
      this.page = temp;
    },
    won() {
      return this.winByGrid;
    },
  },
};
</script>
  
  <style>
.v-card {
  display: flex !important;
  flex-direction: column;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
#custom-scroll {
  scrollbar-width: auto;
  scrollbar-color: var(--blue) rgba(0, 0, 0, 0);
  overflow-y: scroll;
}

/* Chrome, Edge, and Safari */
#custom-scroll::-webkit-scrollbar {
  width: 4px;
}

#custom-scroll::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}

#custom-scroll::-webkit-scrollbar-thumb {
  background-color: var(--blue);
  border-radius: 4px;
  border: 0;
}
</style>