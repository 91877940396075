import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import { db, sbfetch } from "./supabaseDb.js";
import { api } from "./arkadium.js";

Vue.config.productionTip = false;

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
};

firebase.initializeApp(firebaseConfig);
const analytics = firebase.analytics();

Vue.prototype.$analytics = analytics;
// eslint-disable-next-line no-undef
Vue.prototype.$tour = new tourguide.TourGuideClient({
  autoScroll: false,
  closeButton: false,
  dialogClass: "tour-guide",
  dialogMaxWidth: 300,
  dialogWidth: 275,
  exitOnClickOutside: false,
  finishLabel: "Let's play!",
  keyboardControls: false,
  progressBar: "#7C93CB",
  propagateEvents: true,
  showStepDots: false,
  steps: [
    {
      content:
        'Welcome to Blockables! Let\'s learn how to play.<br /><br /><button onclick="progressTutorial()" style="background-color: var(--blue); border-color: var(--blue);" class="white--text mb-2 v-btn v-btn--block v-btn--is-elevated v-btn--has-bg theme--light v-size--default">Start tutorial</button><br /><button onclick="exitTutorialAndGoHome()" style="background-color: grey; border-color: grey;" class="white--text mb-2 v-btn v-btn--block v-btn--is-elevated v-btn--has-bg theme--light v-size--default">I know how to play</button>',
      title: "Welcome to Blockables!",
      target: null,
      step: "1",
      group: "0",
    },
    {
      content:
        "This is the clue list. The answers to these clues go in the grid. The answer to the first clue is 'FWD'.",
      title: "The Clue List",
      target: "#clue-container",
      step: "2",
      group: "0",
    },
    {
      content:
        "This is the grid, where you'll answer the clues. The first letter of the answer <strong>ALWAYS</strong> goes in the numbered block.<br /><br />Click the block, enter 'F', and go to the next part of the tutorial.",
      title: "The Grid",
      target: "[tutorial=example-cell-0-r0c0]",
      step: "3",
      group: "0",
    },
    {
      content:
        "Answer letters must neighbor preceding letters. <br /><br />Click the block, enter 'W', and go to the next part of the tutorial.",
      title: "The Grid",
      target: "[tutorial=example-cell-0-r1c1]",
      step: "4",
      group: "0",
    },
    {
      content:
        "Click the block, enter 'D', and go to the next part of the tutorial.",
      title: "The Grid",
      target: "[tutorial=example-cell-0-r2c1]",
      step: "5",
      group: "0",
    },
    {
      content:
        "There is a secret phrase spiraling around the grid. As you solve more clues, the phrase will reveal itself!",
      title: "The Secret Phrase",
      target: "[tutorial=example-cell-0-unwound-spiral]",
      step: "5",
      group: "0",
    },
    {
      content:
        "Be sure to note the hint at the top of the puzzle for help with the Secret Phrase!",
      title: "The Hint",
      target: "[tutorial=example-cell-0-category]",
      step: "6",
      group: "0",
    },
    {
      content:
        "You're ready to play! If you get stuck, use the CHECK and REVEAL helpers in the top right corner.",
      title: "That's it!",
      target: null,
      step: "7",
      group: "0",
    },
    {
      content:
        'Welcome to Round 2! Click the "Next" button to continue the tutorial!',
      title: "Welcome to Blockables Round 2!",
      target: null,
      step: "1",
      group: "1",
    },
    {
      content:
        'The answer to the first clue is "UMA". Enter "UMA" in, with the first letter "U" starting in the numbered block. Seems easy enough...',
      title: "Clue #1",
      target: "tr:has(#r0c0)",
      step: "2",
      group: "1",
    },
    {
      content:
        'The answer to the second clue is "DIE". We know the first letter (D) goes in the numbered square, but since the other two squares are both neighbors to the first, we don\'t know which way they go yet.<br /><br />Enter "D" in the numbered square and let\'s keep going...',
      title: "Clue #2",
      target: "#r1c0",
      step: "3",
      group: "1",
    },
    {
      content:
        'The answer to the third clue is "TOY". We know the first letter (T) goes in the numbered square, but since the other two squares are both neighbors to the first, we don\'t know which way they go yet.<br /><br />Enter "T" in the numbered square and let\'s keep going...',
      title: "Clue #3",
      target: "#r2c2",
      step: "4",
      group: "1",
    },
    {
      content:
        'This answer might go counterclockwise instead of clockwise. Select "COUNTER" to change the spiral direction.',
      title: "Spiral Direction",
      target: "[tutorial=example-cell-1-directions]",
      step: "5",
      group: "1",
    },
    {
      content:
        "Don't forget to use the hint at the top and the CHECK and REVEAL dropdowns if you get stuck. You'll make it!",
      title: "You're on your own!",
      target: null,
      step: "5",
      group: "1",
    },
    {
      content:
        "Time for the real thing! You have done well to get here.<br /><br />Don't forget to use the CHECK and REVEAL dropdowns if you get stuck. Go get 'em!",
      title: "The Five By Five",
      target: null,
      step: "1",
      group: "2",
    },
  ],
});

if (
  ["blockables.app", "coolmath"].includes(
    process.env.VUE_APP_DEPLOYMENT_PLATFORM
  )
) {
  Vue.prototype.$db = db;
  store.$db = db;

  Vue.prototype.$colors = {
    blue: "#7C93CB",
    red: "#EE7B8C",
    green: "#A9D594",
    purple: "#B86BAC",
    pink: "#E6B0D1",
    orange: "#F5C146",
    yellow: "#F3F1A0",
    "light-blue": "#A1DBE3",
    "default-text": "#000",
  };

  Vue.prototype.$ark = { active: false };

  store.$localStorage = localStorage;

  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App),
  }).$mount("#app");
}

if (process.env.VUE_APP_DEPLOYMENT_PLATFORM == "arkadium") {
  require("../public/arena-api-integration-blockables/arena-api-1.2.3.js");
  require("../public/arena-api-integration-blockables/ArenaHelper.js");

  const ArenaHelper = require("../public/arena-api-integration-blockables/ArenaHelper.js");
  Vue.prototype.$ark = { active: true, api: ArenaHelper.getInstance() };

  Vue.prototype.$db = null;

  Vue.prototype.$fetch = sbfetch;
  store.$fetch = Vue.prototype.$fetch;

  Vue.prototype.$colors = {
    blue: "#5897A9",
    red: "#DE7567",
    green: "#838D5D",
    purple: "#C4B461",
    pink: "#F0D7DA",
    orange: "#EB8D50",
    yellow: "#EFC237",
    "light-blue": "#C6DCE9",
    "default-text": "#FFF",
  };

  store.$localStorage = localStorage;

  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App),
  }).$mount("#app");
}

if (process.env.VUE_APP_DEPLOYMENT_PLATFORM == "arkadium-sdk") {
  document.addEventListener("DOMContentLoaded", async () => {
    const arkAPI = new api(true, process.env.VUE_APP_ENVIRONMENT == "dev");
    await arkAPI.initSDK();
    Vue.prototype.$ark = {
      active: true,
      api: arkAPI,
    };
    Vue.prototype.$db = null;

    Vue.prototype.$fetch = sbfetch;
    store.$fetch = Vue.prototype.$fetch;

    Vue.prototype.$colors = {
      blue: "#5897A9",
      red: "#DE7567",
      green: "#838D5D",
      purple: "#C4B461",
      pink: "#F0D7DA",
      orange: "#EB8D50",
      yellow: "#EFC237",
      "light-blue": "#C6DCE9",
      "default-text": "#FFF",
    };

    await Vue.prototype.$ark.api.callAsyncSDKFunction("onTestReady"); //called when game is ready to be shown
    Vue.prototype.$ark.api.initPersistence();

    store.$localStorage = Vue.prototype.$ark.api;

    new Vue({
      router,
      store,
      vuetify,
      render: h => h(App),
    }).$mount("#app");
  });
}
